

import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { BASE_URL } from "../../constant/constant";

const AddBSGState = () => {
  const [state, setState] = useState("");
  const [states, setStates] = useState([]);
  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const handleSubmit = async () => {
    const data = { state };

    try {
      const userId = localStorage.getItem("id");
      console.log(userId, "userId");

      const response = await axios.post(
        `${BASE_URL}/api/v1/addstate/${userId}`,
        data, // The data object should come first
        axiosConfig // axiosConfig is the third parameter
    );
    
      console.log(response.data);
      toast.success("BSG State Added Successfully");
      setState(""); // Reset input field after submission
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getState();
  }, []);

  const getState = async () => {
    try {
      const userId = localStorage.getItem("id");
      console.log(userId, "userId");
      const response = await axios.get(
        `${BASE_URL}/api/v1/getstate/${userId}`,axiosConfig
      );
      console.log(response, "response");
      console.log(response.data);
      setStates(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log(id, "id");
      const response = await axios.delete(
        `${BASE_URL}/api/v1/deletestate/${id}`,axiosConfig,
   
      );
      console.log(response,"response")
      if (response.status ===200) {
        console.log("state deleted successfully");
        toast.success("state deleted successfully");
        getState();
      } else {
        console.error("Failed to delete category:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <>
      <div className=" pt-1 flex items-center justify-center ">
        <div className="bg-white border border-[#4dc9ff]  p-6 rounded-lg shadow-md w-full max-w-sm">
          <h1 className="text-2xl font-bold mb-4 text-center text-gray-800">
            ADD BSG STATE
          </h1>
          <ToastContainer />
          <input
            type="text"
            placeholder="Enter the BSG State"
            value={state}
            onChange={(e) => setState(e.target.value)}
            className="border border-[#4dc9ff] rounded-md w-full p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <button
            onClick={handleSubmit}
            className="border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white py-2 px-4 rounded-md w-full transform active:scale-95 ease-in-out  disabled:opacity-50 transition duration-200"
          >
            Submit
          </button>
        </div>
      </div>

     

      <div className="font-bold text-2xl text-black mb-6 uppercase">
        All BSG State
      </div>
      <div className="space-y-4">
        {states.map((item, index) => {
          return (
            <div
              key={index}
              className="flex justify-between items-center p-2 border border-[#4dc9ff]  bg-white rounded-lg shadow-md hover:bg-gray-50 transition"
            >
              <div className="text-md text-gray-700">{item.state}</div>
              <button
                className="text-red-500 hover:text-red-700 transition"
                onClick={() => handleDelete(item._id)}
              >
                <MdDelete
                  style={{
                    fontSize: 24,
                  }}
                />
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AddBSGState;
