import React, { useEffect, useState } from "react";
import axios from "axios";
import { POST_USER_REGISTER_HWB } from "../../constant/constant";
import { CirclesWithBar } from "react-loader-spinner";

const ParchmentNotIssued = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    getState();
  }, []);

  const getState = async () => {
    try {
      const response = await axios.get(POST_USER_REGISTER_HWB, axiosConfig);
      console.log(response.data, "response");
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <CirclesWithBar
          height="50"
          width="50"
          color="#0c1445"
          outerCircleColor="#0c1445"
          innerCircleColor="#0c1445"
          barColor="#0c1445"
          ariaLabel="circles-with-bar-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className=" flex flex-col items-center justify-center  bg-gray-100  py-4">
      <div className="mb-6 text-center ">
        <h1 className="text-3xl font-bold text-black uppercase">
          Parchment Not Issued
        </h1>
      </div>
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
      <div className="w-full max-w-6xl bg-white p-6 border border-[#4dc9ff] rounded-lg shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {user
            .filter((item) => item.PARCHMENT_NO === "")
            .map((item) => (
              <div
                key={item.bsgUid}
                className="p-4 border border-[#4dc9ff] rounded-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div className="font-semibold text-xl text-gray-800">
                  {item.name}
                </div>
                <div className="text-gray-600 mb-2">
                  <strong>Email:</strong> {item.email}
                </div>
                <div className="text-gray-600 mb-2">
                  <strong>Date of Birth:</strong> {item.dob}
                </div>
                <div className="text-gray-600 mb-2">
                  <strong>BSG UID:</strong> {item.bsgUid}
                </div>
                <div className="text-gray-600 mb-2">
                  <strong>State:</strong> {item.STATE}
                </div>
                <div className="text-gray-600 mb-2">
                  <strong>Issued Date:</strong> {item.ISSUED_DATE || "N/A"}
                </div>
                <div className="text-gray-600 mb-2">
                  <strong>Mobile:</strong> {item.MOBILE}
                </div>
                <div className="text-gray-600 mb-2">
                  <strong>Section:</strong> {item.SECTION}
                </div>
                <div className="text-gray-600 mb-2">
                  <strong>Unit Name:</strong> {item.UNIT_NAME}
                </div>
                <div className="text-gray-600 mb-2">
                  <strong>Education Qualification:</strong>{" "}
                  {item.EDUCATION_QUALIFICATION}
                </div>
                <div className="text-gray-600 mb-2">
                  <strong>Scout Qualification:</strong>{" "}
                  {item.SCOUT_QUALIFICATION}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ParchmentNotIssued;