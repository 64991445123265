import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../constant/constant";
import { ToastContainer, toast } from "react-toastify";

const ShowHwbDashboard = () => {
  const [usersData, setUsers] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [currentViewState, setCurrentViewState] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterType, setFilterType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [totals, setTotals] = useState({
    valid: 0,
    invalid: 0,
    validKYTScout: 0,
    validKYTGuide: 0,
    validKYTCub: 0,
    validKYTBulbul: 0,
    validKYTRover: 0,
    validKYTRanger: 0,
    HC_VALID_AND_KYT_DONE: 0,
    HC_valid_But_KYT_Not_Done: 0,
    HC_INVALID_BUT_KYT_DONE: 0,
  });

  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL}/api/v1/altuser`,
        axiosConfig
      );
      const users = response.data;

      setUsers(users);
      if (!Array.isArray(users)) {
        throw new Error("Fetched data is not an array");
      }

      // const states = Array.from(
      //   new Set(users.map((user) => user.STATE || "Unknown"))
      // );
      const states = Array.from(
        new Set(users.map((user) => (user.STATE || "Unknown").toUpperCase()))
      );

      const aggregatedData = states.map((state) => {
        const today = new Date();

        const formattedToday = today.setHours(0, 0, 0, 0);
        console.log(formattedToday, "dfdjghfdg");

        const stateUsers = users.filter((user) => user.STATE === state);

        const scoutUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Scout"
        );
        const guideUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Guide"
        );
        const cubUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Cub"
        );
        const bulbulUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Bulbul"
        );
        const roversUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Rover"
        );
        const rangerUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Ranger"
        );

        const validUsers = stateUsers?.filter((user) => {
          const Dates = convertDateString(user.RENEWED_UPTO);
          const validDate = new Date(Dates);
          // console.log(validDate, "validDate");
          return validDate && validDate > formattedToday;
        });

        const invalidUsers = stateUsers.filter(
          (user) => convertDateString(user.RENEWED_UPTO) < formattedToday
        );

        const HC_VALID_AND_KYT_DONE = validUsers.filter(
          (user) =>
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const valid = validUsers.length;
        const invalid = invalidUsers.length;

        const HC_valid_But_KYT_Not_Done = validUsers.filter(
          (user) =>
            !(
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            )
        ).length;

        const HC_INVALID_BUT_KYT_DONE = invalidUsers.filter(
          (user) =>
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTScout = scoutUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTGuide = guideUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTCub = cubUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTBulbul = bulbulUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTRover = roversUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTRanger = rangerUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        return {
          state,
          valid,
          validKYTScout,
          invalid,
          stateUsers,
          guideUsers,
          scoutUsers,
          cubUsers,
          bulbulUsers,
          roversUsers,
          rangerUsers,
          validKYTBulbul,
          validKYTCub,
          validKYTRanger,
          validKYTRover,
          validKYTGuide,
          HC_valid_But_KYT_Not_Done,
          HC_INVALID_BUT_KYT_DONE,
          HC_VALID_AND_KYT_DONE,
        };
      });

      // Sort the aggregated data by state in ascending order
      aggregatedData.sort((a, b) => a.state.localeCompare(b.state));

      // Set the sorted state data
      setStateData(aggregatedData);
      // Calculate totals
      const totals = aggregatedData.reduce(
        (acc, data) => {
          acc.valid += data.valid;
          acc.invalid += data.invalid;
          acc.validKYTScout += data.validKYTScout;
          acc.validKYTGuide += data.validKYTGuide;
          acc.validKYTCub += data.validKYTCub;
          acc.validKYTBulbul += data.validKYTBulbul;
          acc.validKYTRover += data.validKYTRover;
          acc.validKYTRanger += data.validKYTRanger;
          acc.HC_VALID_AND_KYT_DONE += data.HC_VALID_AND_KYT_DONE;
          acc.HC_valid_But_KYT_Not_Done += data.HC_valid_But_KYT_Not_Done;
          acc.HC_INVALID_BUT_KYT_DONE += data.HC_INVALID_BUT_KYT_DONE;
          return acc;
        },
        {
          valid: 0,
          invalid: 0,
          validKYTScout: 0,
          validKYTGuide: 0,
          validKYTCub: 0,
          validKYTBulbul: 0,
          validKYTRover: 0,
          validKYTRanger: 0,
          HC_VALID_AND_KYT_DONE: 0,
          HC_valid_But_KYT_Not_Done: 0,
          HC_INVALID_BUT_KYT_DONE: 0,
        }
      );

      setTotals(totals);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const convertDateString = (dateString) => {
    const parts = dateString?.split("-");
    // if (parts.length !== 3) return null;
    if (!parts || parts?.length !== 3) {
      return null;
    }

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day);

    return date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
      ? date
      : null;
  };

  const handleCloseModal = () => {
    setSelectedUser(null); // Close the modal
  };
  const handleView = (userId) => {
    console.log(userId, "userId");
    const user = filteredUsers.find((user) => user._id === userId);
    console.log(user, "user");
    setSelectedUser(user); // Set the selected user
  };
  const handleSendingEmail = async (email) => {
    try {
      setIsSending(true);
      const response = await axios.post(
        `${BASE_URL}/api/v1/sendEmail`,
        { email },
        axiosConfig
      );
      const result = response.data;

      if (result.success) {
        toast.success("Email sent successfully!");
      } else {
        toast.error("Failed to send email: " + result.message);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("An error occurred while sending the email.");
    }
    setIsSending(false);
  };

  const handleViewDetails = (state, type) => {
    const stateUsers =
      stateData.find((data) => data.state === state)?.stateUsers || [];
    const scoutUsers =
      stateData.find((data) => data.state === state)?.scoutUsers || [];
    const guideUsers =
      stateData.find((data) => data.state === state)?.guideUsers || [];
    const cubUsers =
      stateData.find((data) => data.state === state)?.cubUsers || [];
    const bulbulUsers =
      stateData.find((data) => data.state === state)?.bulbulUsers || [];
    const roversUsers =
      stateData.find((data) => data.state === state)?.roversUsers || [];
    const rangerUsers =
      stateData.find((data) => data.state === state)?.rangerUsers || [];

    let filtered = [];
    const today = new Date();

    const formattedToday = today.setHours(0, 0, 0, 0);

    if (state === "All States") {
      // Handle the case for all states
      filtered = usersData.filter((user) => {
        switch (type) {
          case "valid":
            return convertDateString(user.RENEWED_UPTO) > formattedToday;

          case "invalid":
            return convertDateString(user.RENEWED_UPTO) < formattedToday;
          case "HC_INVALID_BUT_KYT_DONE":
            return (
              convertDateString(user.RENEWED_UPTO) < formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            );

            case "HC_VALID_AND_KYT_DONE":
              return (
                convertDateString(user.RENEWED_UPTO) > formattedToday &&
                
                  (user.dob &&
                  user.dob !== "NA" &&
                  user.bsgUid &&
                  user.bsgUid !== "NA" &&
                  user.email &&
                  user.email !== "NA"
                  )
              );
          case "HC_valid_But_KYT_Not_Done":
            return (
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              !(
                user.dob &&
                user.dob !== "NA" &&
                user.bsgUid &&
                user.bsgUid !== "NA" &&
                user.email &&
                user.email !== "NA"
              )
            );

          default:
            return false;
        }
      });
    } else {
      switch (type) {
        case "valid":
          filtered = stateUsers.filter(
            (user) => convertDateString(user?.RENEWED_UPTO) > formattedToday
          );
          break;

        case "HC_VALID_AND_KYT_DONE":
          filtered = stateUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;

        case "HC_valid_But_KYT_Not_Done":
          filtered = stateUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              !(
                user.dob &&
                user.dob !== "NA" &&
                user.bsgUid &&
                user.bsgUid !== "NA" &&
                user.email &&
                user.email !== "NA"
              )
          );
          break;

        case "HC_INVALID_BUT_KYT_DONE":
          filtered = stateUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) < formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTScout":
          filtered = scoutUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTGuide":
          filtered = guideUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTCub":
          filtered = cubUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTBulbul":
          filtered = bulbulUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTRover":
          filtered = roversUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTRanger":
          filtered = rangerUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "invalid":
          filtered = stateUsers.filter(
            (user) => convertDateString(user.RENEWED_UPTO) < formattedToday
          );
          break;

        default:
          break;
      }
    }
    setCurrentViewState(state);
    setFilterType(type);
    setFilteredUsers(filtered);
  };

  const handleBackToDashboard = () => {
    setCurrentViewState(null);
    setFilterType(null);
    setFilteredUsers([]);
  };

  const renderStateTable = () => (
    <>
      <div className="max-h-[400px] overflow-y-auto">
        <table className="min-w-full bg-white border rounded-lg shadow-lg">
          <thead className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
            <tr>
              {[
                "State",
                "Valid",
                "Invalid",
                "Valid-KYT",
                "Invalid+KYT",
                "Valid+KYT",
                "Scout",
                "Guide",
                "Cub",
                "Bulbul",
                "Rover",
                "Ranger",
              ].map((header) => (
                <th
                  key={header}
                  className="px-2 py-1 text-left text-xs font-semibold uppercase sticky top-0 bg-gradient-to-r from-indigo-600 to-purple-600"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(stateData) && stateData.length > 0 ? (
              stateData.map((data, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-100 transition-all duration-200 border-b last:border-none"
                >
                  <td className="px-4 py-1 text-gray-700 font-medium text-xs">
                    {data.state}
                  </td>
                  <td
                    className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() => handleViewDetails(data.state, "valid")}
                  >
                    {data.valid}
                  </td>
                  <td
                    className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() => handleViewDetails(data.state, "invalid")}
                  >
                    {data.invalid}
                  </td>
                  <td
                    className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() =>
                      handleViewDetails(data.state, "HC_valid_But_KYT_Not_Done")
                    }
                  >
                    {data.HC_valid_But_KYT_Not_Done}
                  </td>
                  <td
                    className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() =>
                      handleViewDetails(data.state, "HC_INVALID_BUT_KYT_DONE")
                    }
                  >
                    {data.HC_INVALID_BUT_KYT_DONE}
                  </td>
                  <td
                    className="px-4 py-1 cursor-pointer hover:text-blue-500 bg-red-500 text-white"
                    onClick={() =>
                      handleViewDetails(data.state, "HC_VALID_AND_KYT_DONE")
                    }
                  >
                    {data.HC_VALID_AND_KYT_DONE}
                  </td>
                  <td
                    className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() =>
                      handleViewDetails(data.state, "validKYTScout")
                    }
                  >
                    {data.validKYTScout}
                  </td>
                  <td
                    className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() =>
                      handleViewDetails(data.state, "validKYTGuide")
                    }
                  >
                    {data.validKYTGuide}
                  </td>
                  <td
                    className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() => handleViewDetails(data.state, "validKYTCub")}
                  >
                    {data.validKYTCub}
                  </td>
                  <td
                    className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() =>
                      handleViewDetails(data.state, "validKYTBulbul")
                    }
                  >
                    {data.validKYTBulbul}
                  </td>
                  <td
                    className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() =>
                      handleViewDetails(data.state, "validKYTRover")
                    }
                  >
                    {data.validKYTRover}
                  </td>
                  <td
                    className="px-4 py-1 text-gray-700 cursor-pointer hover:text-blue-500"
                    onClick={() =>
                      handleViewDetails(data.state, "validKYTRanger")
                    }
                  >
                    {data.validKYTRanger}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  No data available
                </td>
              </tr>
            )}

            <tr className="font-bold bg-gray-200 sticky bottom-0">
              <td className="px-4 py-1 text-gray-700">Total</td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() => handleViewDetails("All States", "valid")}
              >
                {totals.valid}
              </td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() => handleViewDetails("All States", "invalid")}
              >
                {totals.invalid}
              </td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() =>
                  handleViewDetails("All States", "HC_valid_But_KYT_Not_Done")
                }
              >
                {totals.HC_valid_But_KYT_Not_Done}
              </td>
              <td
                className="px- 4 py-1 text-gray-700 cursor-pointer"
                onClick={() =>
                  handleViewDetails("All States", "HC_INVALID_BUT_KYT_DONE")
                }
              >
                {totals.HC_INVALID_BUT_KYT_DONE}
              </td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() =>
                  handleViewDetails("All States", "HC_VALID_AND_KYT_DONE")
                }
              >
                {totals.HC_VALID_AND_KYT_DONE}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals.validKYTScout}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals.validKYTGuide}
              </td>
              <td className="px-4 py-1 text-gray-700">{totals.validKYTCub}</td>
              <td className="px-4 py-1 text-gray-700">
                {totals.validKYTBulbul}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals.validKYTRover}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals.validKYTRanger}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <h1 className="font-bold p-2">Send Email for All HC_INVALID_BUT_KYT_DONE</h1>
     <div className="bg-red-500 rounded-md px-2 w-32  text-white" onClick={()=>{handleSendingEmail(HC_INVALID_BUT_KYT_DONEEmails)}}>Send Email </div> */}
      <h1 className="text-md font-bold  text-gray-800 mb-2 mt-3">
        Send Email for all trainers whose Hounourable Charge renewal is due
      </h1>
      <div
        className="bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 rounded-md px-2 py-1 w-36 text-center text-white font-semibold cursor-pointer shadow-lg transform transition duration-300 hover:scale-105"
        onClick={() => handleSendingEmail(HC_INVALID_BUT_KYT_DONEEmails)}
      >
        {isSending ? "Sending..." : "Send Email"}
      </div>
    </>
  );
  const renderFilteredUsersTable = () => (
    <div className="">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center mb-3">
        <div>
          <h2 className="text-md font-bold text-gray-800  uppercase tracking-wide">
            {`${currentViewState}`}
          </h2>
          <h2 className="text-md font-bold text-gray-800">
            {`(${filterType.toUpperCase()})`}
          </h2>
        </div>
        <div className="flex justify-end">
          <button
            className="px-6 py-2 bg-[#1D56A5] text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-400 transition-all duration-300"
            onClick={handleBackToDashboard}
          >
            Back to Dashboard
          </button>
        </div>
      </div>

      <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gradient-to-r from-green-600 to-teal-600 text-white">
          <tr>
            {[
              "No",
              "Name",
              "State",
              "Section",
              "Renewed Upto",
              "Email",
              "Action",
            ].map((header) => (
              <th
                key={header}
                className="px-4 py-1 text-left text-sm font-semibold uppercase"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
            filteredUsers.map((user, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 transition-all duration-200 border-b last:border-none"
              >
                <td className="px-1 py-1 text-gray-700  text-xs font-medium">
                  {index + 1}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.name || "N/A"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.STATE || "Unknown"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.SECTION || "N/A"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.RENEWED_UPTO || "22-09-2029"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.email}
                </td>
                <td
                  className="px-1 py-1  text-xs  font-bold cursor-pointer text-red-500"
                  onClick={() => handleView(user._id)}
                >
                  View All
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No users found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  const today = new Date();

  const formattedToday = today.setHours(0, 0, 0, 0);
  const HC_INVALID_BUT_KYT_DONEEmails = usersData
    .filter(
      (user) =>
        convertDateString(user.RENEWED_UPTO) < formattedToday &&
        user.dob &&
        user.dob !== "NA" &&
        user.bsgUid &&
        user.bsgUid !== "NA" &&
        user.email &&
        user.email !== "NA"
    )
    .map((user) => user.email);
  return (
    <>
      <div className="px-4 sm:px-8 py-5 bg-gradient-to-r from-blue-50 to-purple-50 min-h-screen">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-extrabold text-indigo-700 uppercase">
            ALT Statewise Dashboard
          </h1>
          <p className="text-gray-600 text-lg ">
            An overview of statewise user statistics
          </p>
        </div>
        {loading ? (
          <div className="flex justify-center items-center min-h-[200px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-600 border-opacity-70"></div>
          </div>
        ) : (
          <div className="bg-white rounded-xl p-5 shadow-2xl">
            {currentViewState ? renderFilteredUsersTable() : renderStateTable()}
          </div>
        )}
      </div>
      {selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-xl">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6 border-b pb-3">
              User Details
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm  text-black font-bold">Name</p>
                <p className="text-gray-800">{selectedUser.name || "N/A"}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Email</p>
                <p className="text-gray-800">{selectedUser.email}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">DOB</p>
                <p className="text-gray-800">{selectedUser.dob}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">BSG UID</p>
                <p className="text-gray-800">{selectedUser.bsgUid}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">
                  Honourable Charge No
                </p>
                <p className="text-gray-800">
                  {selectedUser.HONOURABLE_CHARGE_NO ||
                    selectedUser.honourableNumber}
                </p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">State</p>
                <p className="text-gray-800">{selectedUser.STATE}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Status</p>
                <p className="text-gray-800">{selectedUser.STATUS}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Region</p>
                <p className="text-gray-800">{selectedUser.REGION}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Issued Date</p>
                <p className="text-gray-800">{selectedUser.DATE}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Admin Status</p>
                <p className="text-gray-800">{selectedUser.status}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Renewed Upto</p>
                <p className="text-gray-800">{selectedUser.RENEWED_UPTO}</p>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={handleCloseModal}
                className="bg-[#1D56A5] text-white py-2 px-4 rounded-lg shadow"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowHwbDashboard;
