import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-modal";
import * as XLSX from "xlsx";
import { BASE_URL } from "../../constant/constant";

Modal.setAppElement("#root");

const SearchComponent = () => {
  const [states, setStates] = useState([]);
  const [sections, setSections] = useState([]);
  const [startDob, setStartDob] = useState("");
  const [endDob, setEndDob] = useState("");
  const [startissuedate, setStartIssueDate] = useState("");
  const [endissuedate, setEndIssueDate] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [uid, setUid] = useState("");
  const [email, setEmail] = useState("");
  const [educationQualification, setEducationQualification] = useState([]);
  const [selectedEducationQualification, setSelectedEducationQualification] =
    useState("");
  const [startparchmentNumber, setStartparchmentNumber] = useState("");
  const [endparchmentNumber, setEndparchmentNumber] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [noDataFound, setNoDataFound] = useState(false);
  const itemsPerPage = 20;

  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const exportToExcel = () => {
    const altDetailsData = filteredUsers.map((user) => {
      const { __v, createdAt, updatedAt, _id, ...details } = user.altDetails;
      return details;
    });

    const worksheet = XLSX.utils.json_to_sheet(altDetailsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = "filtered_data.xlsx";
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const clearAll = () => {
    setSelectedStates([]);
    setStartDob("");
    setEndDob("");
    setStartIssueDate("");
    setEndIssueDate("");
    setUid("");
    setEmail("");
    setSelectedEducationQualification("");
    setStartparchmentNumber("");
    setEndparchmentNumber("");
    setSelectedSection("");
    setCurrentPage(1);
    setFilteredUsers(users);
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/user`, axiosConfig);
      const usersData = response.data;

      if (Array.isArray(usersData) && usersData.length > 0) {
        setUsers(usersData);
        setFilteredUsers(usersData);

        const uniqueStates = [
          ...new Set(
            usersData
              .map((user) => user.altDetails?.STATE?.trim().toLowerCase())
              .filter(Boolean)
          ),
        ];

        const uniqueSections = [
          ...new Set(
            usersData
              .map((user) => user.altDetails?.SECTION?.trim().toLowerCase())
              .filter(Boolean)
          ),
        ];
        const uniqueEducationQualifications = [
          ...new Set(
            usersData
              .map((user) =>
                user.altDetails?.EDUCATION_QUALIFICATION?.trim().toLowerCase()
              )
              .filter(Boolean)
          ),
        ];

        setEducationQualification(uniqueEducationQualifications);
        setStates(uniqueStates);
        setSections(uniqueSections);
      } else {
        console.log("No user data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStateSelection = (selectedOptions) => {
    setSelectedStates(selectedOptions || []);
  };

  const handleSectionSelection = (selectedOptions) => {
    setSelectedSection(selectedOptions || []);
  };

  const handleEducationSelection = (selectedOptions) => {
    setSelectedEducationQualification(selectedOptions || []);
  };

  const handleSearch = () => {
    const filteredData = users.filter((item) => {
      const issuedate = new Date(item.altDetails?.ISSUED_DATE);
      const isIssueDate =
        (!startissuedate || issuedate >= new Date(startissuedate)) &&
        (!endissuedate || issuedate <= new Date(endissuedate));

      const dob = new Date(item.altDetails?.dob);
      const isDobInRange =
        (!startDob || dob >= new Date(startDob)) &&
        (!endDob || dob <= new Date(endDob));

      const parchmentNumber = item.altDetails?.PARCHMENT_NO;
      const isParchmentInRange =
        (!startparchmentNumber || parchmentNumber >= startparchmentNumber) &&
        (!endparchmentNumber || parchmentNumber <= endparchmentNumber);

      const isStateSelected =
        selectedStates.length === 0 ||
        selectedStates.some(
          (state) =>
            state.value === item.altDetails?.STATE?.trim().toLowerCase()
        );

      return (
        isParchmentInRange &&
        isIssueDate &&
        isStateSelected &&
        (selectedEducationQualification
          ? item.altDetails?.EDUCATION_QUALIFICATION ===
            selectedEducationQualification
          : true) &&
        (startDob || endDob ? isDobInRange : true) &&
        (startissuedate || endissuedate ? isDobInRange : true) &&
        (uid ? item?.bsgUid === uid : true) &&
        (email ? item?.email === email : true) &&
        (startparchmentNumber || endparchmentNumber
          ? isParchmentInRange
          : true) &&
        (selectedSection
          ? item.altDetails?.SECTION?.trim().toLowerCase() === selectedSection
          : true)
      );
    });
    setIsOpen(false);
    setFilteredUsers(filteredData.length > 0 ? filteredData : users);
    setNoDataFound(filteredData.length === 0);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className="text-2xl font-bold mb-2 text-center text-gray-800 uppercase">
        HWB Records
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-1 mb-6 ">
        <div className="relative">
          <div className="relative mb-3 w-full">
            <label className="block text-sm font-medium text-black uppercase">
              State
            </label>         
            <Select
              isMulti
              options={states.map((state) => ({ value: state, label: state }))}
              value={selectedStates}
              onChange={handleStateSelection}
              className="basic-multi-select border border-[#4dc9ff] rounded-md"
              classNamePrefix="select"
              placeholder="Select States"
              styles={{
                control: (base) => ({
                  ...base,
                  minWidth: "200px",
                  width: "100%",
                }),
                multiValue: (base) => ({
                  ...base,
                  padding: "2px 2px",
                  fontSize: "10px",
                  minHeight: "3px",
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: "10px",
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  fontSize: "2px",
                }),
              }}
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-black  uppercase">
            DOB (From)
          </label>
          <input
            type="date"
            value={startDob}     
            onChange={(e) => setStartDob(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black  uppercase">
            DOB (To)
          </label>
          <input
            type="date"
            value={endDob}
            onChange={(e) => setEndDob(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black  uppercase">
            ISSUE DATE (From)
          </label>
          <input
            type="date"
            value={startissuedate}
            onChange={(e) => setStartIssueDate(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black uppercase">
            ISSUE DATE (To)
          </label>
          <input
            type="date"
            value={endissuedate}
            onChange={(e) => setEndIssueDate(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
          />
        </div>


        <div className="relative mb-3 w-full">
            <label className="block text-sm font-medium text-black uppercase">
            Section
            </label>         
            <Select
              isMulti
              options={sections.map((section) => ({ value: section, label: section }))}
              value={selectedSection}
              onChange={handleSectionSelection}
              className="basic-multi-select border border-[#4dc9ff] rounded-md"
              classNamePrefix="select"
              placeholder="Select Section"
              styles={{
                control: (base) => ({
                  ...base,
                  minWidth: "200px",
                  width: "100%",
                }),
                multiValue: (base) => ({
                  ...base,
                  padding: "2px 2px",
                  fontSize: "10px",
                  minHeight: "3px",
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: "10px",
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  fontSize: "2px",
                }),
              }}
            />
          </div>

        <div className="">
          <label className="block text-sm font-medium text-black ">
            BSG UID
          </label>
          <input
            type="text"
            placeholder="Enter BSG UID"
            value={uid}
            onChange={(e) => setUid(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm text-black"
          />
        </div>

        <div className="">
          <label className="block text-sm font-medium text-black uppercase ">
            Email ID
          </label>
          <input
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
          />
        </div>


        <div className="relative mb-3 w-full">
            <label className="block text-sm font-medium text-black uppercase">
           Select Qualification
            </label>         
            <Select
              isMulti
              options={educationQualification.map((educationQualifications) => ({ value: educationQualifications, label: educationQualifications}))}
              value={selectedEducationQualification}
              onChange={ handleEducationSelection }
              className="basic-multi-select border border-[#4dc9ff] rounded-md"
              classNamePrefix="select"
              placeholder="Select Qualification"
              styles={{
                control: (base) => ({
                  ...base,
                  minWidth: "200px",
                  width: "100%",
                }),
                multiValue: (base) => ({
                  ...base,
                  padding: "2px 2px",
                  fontSize: "10px",
                  minHeight: "3px",
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: "10px",
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  fontSize: "2px",
                }),
              }}
            />
          </div>

        <div>
          <label className="block text-sm font-medium text-black uppercase">
            PARCHMENT (From)
          </label>
          <input
            type="text"
            placeholder="Enter Number"
            value={startparchmentNumber}
            onChange={(e) => setStartparchmentNumber(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black uppercase">
            PARCHMENT (To)
          </label>
          <input
            type="text"
            value={endparchmentNumber}
            placeholder="Enter Number"
            onChange={(e) => setEndparchmentNumber(e.target.value)}
            className=" w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
          />
        </div>
        <button
  onClick={clearAll}
  className="py-0 mt-[18px] px-6 h-10 bg-red-900 border border-[#ac5a5a] text-white text-sm font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out flex items-center justify-center"
>
  Clear <span className="ml-1 font-semibold">All</span>
</button>

      </div>
      <div className="flex flex-row gap-5 ">
        <button
          onClick={handleSearch}
          className="w-full py-1 px-4 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out "
        >
          Search
        </button>
        <button
          onClick={exportToExcel}
          className="w-full py-1 px-4 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out "
        >
          Export to Excel
        </button>
      </div>
      <div className="flex items-center justify-center my-5 w-auto ">
        {isLoading ? (
          <ClipLoader color="#1D56A5" size={50} />
        ) : (
          <div className=" w-full  max-w-4xl mt-4   rounded-lg shadow-lg bg-white overflow-x-auto   ">
            <table className=" bg-white shadow-md rounded-lg ">
              <thead className="border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445]  text-white">
                <tr>
                  {[
                    "SL",
                    "NAME",
                    "DOB",
                    "BSG UID",
                    "Email",
                    "State",
                    "Section",
                    "QUAL.",
                    "PARCH NO.",
                    "ISSUED DATE",
                    "Actions",
                  ].map((header, index) => (
                    <th
                      key={index}
                      className="py-1 px-8 text-xs font-semibold uppercase tracking-wide text-center "
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {noDataFound ? (
                  <tr>
                    <td colSpan={11} className="py-4 text-center text-gray-500">
                      Data not found
                    </td>
                  </tr>
                ) : (
                  currentUsers.map((user, index) => (
                    <tr
                      key={user.id}
                      className="hover:bg-blue-50 transition duration-200 ease-in-out"
                    >
                      <td className="py-1 px-2 text-xs text-gray-800 text-center">
                        {indexOfFirstItem + index + 1}
                      </td>
                      <td className="py-1 px-2 text-xs text-gray-800 text-center">
                        {user.altDetails?.name}
                      </td>
                      <td className="py-1 px-2 text-xs text-gray-800 text-center">
                        {user.altDetails?.dob}
                      </td>
                      <td className="py-1 px-2 text-xs text-gray-800 text-center">
                        {user?.bsgUid}
                      </td>
                      <td className="py-1 px-2 text-xs text-gray-800 text-center">
                        {user?.email}
                      </td>
                      <td className="py-1 px-2 text-xs text-gray-800 text-center">
                        {user.altDetails?.STATE}
                      </td>
                      <td className="py-1 px-2 text-xs text-gray-800 text-center">
                        {user.altDetails?.SECTION}
                      </td>
                      <td className="py-1 px-2 text-xs text-gray-800 text-center">
                        {user.altDetails?.EDUCATION_QUALIFICATION}
                      </td>
                      <td className="py-1 px-2 text-xs text-gray-800 text-center">
                        {user.altDetails?.PARCHMENT_NO}
                      </td>
                      <td className="py-1 px-2 text-xs text-gray-800 text-center">
                        {user.altDetails?.ISSUED_DATE}
                      </td>
                      <td
                        className="py-1 px-2 text-xs text ```javascript
                      gray-800 text-center"
                      >
                        <button
                          onClick={() => openModal(user)}
                          className="px-4 py-2 bg-blue-600 text-white text-xs rounded hover:bg-blue-700"
                        >
                          View All
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {selectedUser && (
                <div className="bg-white shadow-md rounded-lg p-6">
                  <h2 className="text-2xl font-bold text-gray-800 mb-6 border-b pb-2 uppercase">
                    User Details
                  </h2>
                  <div className="grid grid-cols-2 gap-4 text-gray-700">
                    <div className="flex">
                      <strong className="w-48 text-black">Name:</strong>
                      <span>{selectedUser.altDetails.name}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">DOB:</strong>
                      <span>{selectedUser.altDetails.dob}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">BSG UID:</strong>
                      <span>{selectedUser.bsgUid}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Email:</strong>
                      <span>{selectedUser.email}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black ">Mobile:</strong>
                      <span>{selectedUser.altDetails.mobile}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">State:</strong>
                      <span>{selectedUser.altDetails.state}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Section:</strong>
                      <span>{selectedUser.altDetails.section}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Unit:</strong>
                      <span>{selectedUser.altDetails.nameofunit}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">
                        Qualification:
                      </strong>
                      <span>{selectedUser.altDetails.scoutqualification}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">
                        Education Qualification:
                      </strong>
                      <span>
                        {selectedUser.altDetails.educstionqualification}
                      </span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Parchment No:</strong>
                      <span>{selectedUser.altDetails.HWB_PARCHMENT_NO}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Issued Date:</strong>
                      <span>{selectedUser.altDetails.ISSUED_DATE}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Address:</strong>
                      <span>{selectedUser.altDetails.address}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Remarks:</strong>
                      <span>{selectedUser.altDetails.REMARKS}</span>
                    </div>
                  </div>
                  <button
                    onClick={closeModal}
                    className="mt-6 px-6 py-2 bg-[#1d56a5] hover:bg-blue-700 text-white rounded-md transition-all duration-300 ease-in-out"
                  >
                    Close
                  </button>
                </div>
              )}
            </Modal>
          </div>
        )}
      </div>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 m-5 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 ease-in-out text-xs  hover:bg-blue-700 disabled:opacity-50 transition duration-200"
        >
          Previous
        </button>
        <span className="text-xs text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 m-5 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 ease-in-out text-xs  disabled:opacity-50 transition duration-200"
        >
          Next
        </button>
      </div>
    </>
  );
};

export default SearchComponent;