import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-modal";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import * as XLSX from "xlsx";
import { BASE_URL } from "../../constant/constant";
import { ToastContainer, toast } from "react-toastify";
Modal.setAppElement("#root");

const AltList = () => {
  const [states, setStates] = useState([]);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [sections, setSections] = useState([]);
  const [startDob, setStartDob] = useState("");
  const [endDob, setEndDob] = useState("");
  const [startissuedate, setStartIssueDate] = useState("");
  const [endissuedate, setEndIssueDate] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [uid, setUid] = useState("");
  const [region, setRegion] = useState("");
  const [email, setEmail] = useState("");
  const [honourable, setHonourable] = useState("");
  const [educationQualification, setEducationQualification] = useState([]);
  const [selectedEducationQualification, setSelectedEducationQualification] =
    useState("");
  const [startparchmentNumber, setStartparchmentNumber] = useState("");
  const [endparchmentNumber, setEndparchmentNumber] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const [editUser, setEditUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null); // State to hold the selected user for modal
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [noDataFound, setNoDataFound] = useState(false); // State to control no data found message
  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  // Function to export data to Excel
  // const exportToExcel = () => {
  //   const altDetailsData = filteredUsers.map((user) => {
  //     const { __v, createdAt, updatedAt, _id, ...details } = user.altDetails;
  //     return details;
  //   });

  //   const worksheet = XLSX.utils.json_to_sheet(altDetailsData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   const data = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });
  //   const url = window.URL.createObjectURL(data);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "filtered_data.xlsx";
  //   link.click();
  //   window.URL.revokeObjectURL(url);
  // };
  const exportToExcel = () => {
    // Check if there are any filtered users to export
    if (filteredUsers.length === 0) {
      toast.error("No data available to export.");
      return;
    }
  
    // Map the filtered users to the desired format
    const altDetailsData = filteredUsers.map((user) => {
      const { __v, createdAt, updatedAt, _id, ...details } = user.altDetails || user; // Use user.altDetails if it exists, otherwise use user directly
      return {
        Name: details.name,
        State: details.STATE,
        Section: details.SECTION,
        HonourableChargeNo: details.HONOURABLE_CHARGE_NO,
        RenewedUpto: details.RENEWED_UPTO,
        Status: details.STATUS,
        Age: calculateAge(details.dob),
        DOB: details.dob,
        EducationQualification: details.EDUCATION_QUALIFICATION,
        // Add any other fields you want to include in the export
      };
    });
  
    // Create a worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(altDetailsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ALT Records");
  
    // Write the workbook to a binary string
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
  
    // Create a Blob from the binary string
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
  
    // Create a link element to download the file
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = "ALT_Records.xlsx"; // Set the file name
    document.body.appendChild(link); // Append the link to the body
    link.click(); // Trigger the download
    document.body.removeChild(link); // Remove the link from the body
    window.URL.revokeObjectURL(url); // Revoke the object URL
  };

  const clearAll = () => {
    setSelectedStates([]);
    setSelectedStatus([]);
    setStartDob("");
    setEndDob("");
    setStartIssueDate("");
    setEndIssueDate("");
    setUid("");
    setEmail("");
    setRegion("");
    setHonourable("");
    setSelectedEducationQualification("");
    setStartparchmentNumber("");
    setEndparchmentNumber("");
    setSelectedSection("");
    setCurrentPage(1);
    setFilteredUsers(users);
    setNoDataFound(false);
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/v1/altuser/${editUser._id}`,
        editUser,
        axiosConfig
      );

      if (response.status === 200) {
        toast.success("Data updated successfully");

        const updatedUser = response.data;

        // Update users and filteredUsers lists
        setUsers(
          users.map((user) => (user._id === editUser._id ? updatedUser : user))
        );
        setFilteredUsers(
          filteredUsers.map((user) =>
            user._id === editUser._id ? updatedUser : user
          )
        );

        closeModal(); // Close the modal
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update data. Please try again.");
    }
  };

  const openEditModal = (user) => {
    setEditUser(user); // Set the user data to be edited
    setIsModalOpen(true); // Open the modal
  };

  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);

  const handleClickOutside1 = (event) => {
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setIsOpen1(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside1);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/altuser`,
        axiosConfig
      );
      const usersData = response.data;

      if (Array.isArray(usersData) && usersData.length > 0) {
        setUsers(usersData);
        setFilteredUsers(usersData);
        const uniqueStates = [
          ...new Set(
            usersData
              .map((user) => user.STATE?.trim().toLowerCase())
              .filter(Boolean)
          ),
        ];
        const uniqueSections = [
          ...new Set(
            usersData
              .map((user) => user.SECTION?.trim().toLowerCase())
              .filter(Boolean)
          ),
        ];
        const uniqueEducationQualifications = [
          ...new Set(
            usersData
              .map((user) => user.EDUCATION_QUALIFICATION)
              .filter(Boolean)
          ),
        ];
        const uniqueStatus = [
          ...new Set(usersData.map((user) => user.STATUS).filter(Boolean)),
        ];

        setEducationQualification(uniqueEducationQualifications);
        setStates(uniqueStates);
        setStatus(uniqueStatus);
        setSections(uniqueSections);
      } else {
        console.warn("No user data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStateSelection = (state) => {
    if (selectedStates.includes(state)) {
      setSelectedStates(selectedStates.filter((s) => s !== state));
    } else {
      setSelectedStates([...selectedStates, state]);
    }
  };

  const handleStatusSelection = (status) => {
    if (selectedStatus.includes(status)) {
      setSelectedStatus(selectedStatus.filter((s) => s !== status));
    } else {
      setSelectedStatus([...selectedStatus, status]);
    }
  };

  const handleSearch = () => {
    const filteredData = users.filter((item) => {
      const issuedate = new Date(item.DATE);
      const isIssueDate =
        (!startissuedate || issuedate >= new Date(startissuedate)) &&
        (!endissuedate || issuedate <= new Date(endissuedate));
      const dob = new Date(item.dob);
      const isDobInRange =
        (!startDob || dob >= new Date(startDob)) &&
        (!endDob || dob <= new Date(endDob));
      const parchmentNumber = item.PARCHMENT_NO;
      const isParchmentInRange =
        (!startparchmentNumber || parchmentNumber >= startparchmentNumber) &&
        (!endparchmentNumber || parchmentNumber <= endparchmentNumber);
      const isStateSelected =
        selectedStates.length === 0 ||
        selectedStates.includes(item.STATE?.trim().toLowerCase());
      const isStatusSelected =
        selectedStatus.length === 0 || selectedStatus.includes(item.STATUS);

      return (
        isParchmentInRange &&
        isIssueDate &&
        isStateSelected &&
        isStatusSelected &&
        (startDob || endDob ? isDobInRange : true) &&
        (uid ? item?.bsgUid === uid : true) &&
        (region ? item.REGION === region : true) &&
        (honourable ? item.HONOURABLE_CHARGE_NO === honourable : true) &&
        (selectedEducationQualification
          ? item.EDUCATION_QUALIFICATION === selectedEducationQualification
          : true) &&
        (email ? item?.email === email : true) &&
        (selectedSection
          ? item.SECTION?.trim().toLowerCase() === selectedSection
          : true)
      );
    });

    setFilteredUsers(filteredData.length > 0 ? filteredData : users);
    setNoDataFound(filteredData.length === 0);
    setIsOpen(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const calculateAge = (dob) => {
    const parts = dob?.split("-");
    if (parts?.length === 3) {
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      const birthDate = new Date(formattedDate);

      if (isNaN(birthDate.getTime())) {
        return "Invalid Date";
      }

      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    } else {
      return "Invalid Date";
    }
  };

  return (
    <>
      <div className="text-2xl font-bold mb-4 pb-5 text-center text-gray-800 uppercase">
        ALT RECORDS
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-1 mb-6 ">
        <div className="relative" ref={dropdownRef}>
          <div className="relative mb-3">
            <label className="block text-sm font-medium text-black uppercase">
              State
            </label>

            <button
              onClick={toggleDropdown}
              className="w-full py-1.5 mt-2 px-3 border border-[#4dc9ff] text-sm font-semibold rounded-sm  lowercase "
            >
              <div className="flex justify-between items-center ">
                <span className="text-xs  uppercase">
                  {selectedStates.length > 0
                    ? selectedStates.join(", ")
                    : "Select States"}
                </span>
                <MdOutlineKeyboardArrowDown
                  className={`transform transition-transform duration-200 ${
                    isOpen ? "rotate-180" : ""
                  }`}
                />
              </div>
            </button>

            {isOpen && (
              <div className="absolute z-10 w-full mt-2 bg-white border border-gray-200 rounded-lg ">
                {states.map((state, index) => (
                  <div
                    key={index}
                    onClick={() => handleStateSelection(state)}
                    className={`flex items-center justify-between py-2 px-4 cursor-pointer transition-colors duration-150  hover:bg-blue-50 ${
                      selectedStates.includes(state)
                        ? "bg-blue-300"
                        : "bg-white"
                    }`}
                  >
                    <span className="text-black text-xs uppercase -">
                      {state}
                    </span>
                    {selectedStates.includes(state) && (
                      <span className="text-blue-600 font-bold">✔️</span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-black uppercase">
            DOB (From)
          </label>
          <input
            type="date"
            value={startDob}
            onChange={(e) => setStartDob(e.target.value)}
            className="w-full  py-1 mt-2 px-3 border border-[#4dc9ff] text-sm rounded-sm shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black mb-2 uppercase">
            DOB (To)
          </label>
          <input
            type="date"
            value={endDob}
            onChange={(e) => setEndDob(e.target.value)}
            className="w-full py-1  px-3 border border-[#4dc9ff] text-sm rounded-sm shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black mb-2 uppercase">
            ISSUE DATE (From)
          </label>
          <input
            type="date"
            value={startissuedate}
            onChange={(e) => setStartIssueDate(e.target.value)}
            className="w-full py-1  px-3  border border-[#4dc9ff] text-sm rounded-sm shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-black mb-2 uppercase">
            ISSUE DATE (To)
          </label>
          <input
            type="date"
            value={endissuedate}
            onChange={(e) => setEndIssueDate(e.target.value)}
            className="w-full py-1  px-3  border border-[#4dc9ff] text-sm rounded-sm shadow-sm"
          />
        </div>

        <div className="flex flex-col">
          <label className="block text-sm font-medium  text-black mb-2 uppercase">
            Section
          </label>
          <select
            onChange={(e) => setSelectedSection(e.target.value)}
            value={selectedSection}
            className="w-full py-1  px-3  border border-[#4dc9ff] text-sm rounded-sm shadow-sm"
          >
            <option value="">Select Section</option>
            {sections?.map((section, index) => (
              <option key={index} value={section}>
                {section}      
              </option>
            ))}
          </select>
        </div>

        <div className="">
          <label className="block text-sm font-medium text-black mb-2 ">
            REGION
          </label>
          <input
            type="text"
            placeholder="Enter Region"
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            className="w-full py-1  px-3  border border-[#4dc9ff] text-sm rounded-sm shadow-sm text-black"
          />
        </div>

        <div className="">
          <label className="block text-sm font-medium text-black mb-2 uppercase ">
            Honourable No.
          </label>
          <input
            type="email"
            placeholder="Enter Number"
            value={honourable}
            onChange={(e) => setHonourable(e.target.value)}
            className="w-full py-1  px-3  border border-[#4dc9ff] text-sm rounded-sm shadow-sm"
          />
        </div>

        <div className="">
          <label className="block text-sm font-medium text-black mb-2 uppercase ">
            Qualification
          </label>
          <select
            value={selectedEducationQualification}
            onChange={(e) => setSelectedEducationQualification(e.target.value)}
            className="w-full py-1  px-3  border border-[#4dc9ff] text-sm rounded-sm shadow-sm"
          >
            <option value="">Select Qualification</option>
            {educationQualification.map((educationQualifications, index) => (
              <option key={index} value={educationQualifications}>
                {educationQualifications}
              </option>
            ))}
          </select>
        </div>

        <div className="relative" ref={dropdownRef1}>
          <div className="relative mb-3">
            <label className="block text-sm font-medium text-black uppercase">
              Status
            </label>

            <button
              onClick={toggleDropdown1}
              className="w-full py-1.5 mt-2 px-3 border border-[#4dc9ff] text-sm font-semibold rounded-sm  lowercase "
            >
              <div className="flex justify-between items-center ">
                <span className="text-xs  uppercase">
                  {selectedStatus.length > 0
                    ? selectedStatus.join(", ")
                    : "Select Status"}
                </span>
                <MdOutlineKeyboardArrowDown
                  className={`transform transition-transform duration-200 ${
                    isOpen1 ? "rotate-180" : ""
                  }`}
                />
              </div>
            </button>

            {isOpen1 && (
              <div className="absolute z-10 w-full mt-2 bg-white border border-gray-200 rounded-lg ">
                {status.map((status, index) => (
                  <div
                    key={index}
                    onClick={() => handleStatusSelection(status)}
                    className={`flex items-center justify-between py-2 px-4 cursor-pointer transition-colors duration-150  hover:bg-blue-50 ${
                      selectedStatus.includes(status)
                        ? "bg-blue-300"
                        : "bg-white"
                    }`}
                  >
                    <span className="text-black text-xs uppercase -">
                      {status}
                    </span>
                    {selectedStatus.includes(status) && (
                      <span className="text-blue-600 font-bold">✔️</span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between flex-row">
          <button
            onClick={clearAll}
            className="mt-4 py-0.5 px-6 bg-red-900 border border-[#ac5a5a] text-white font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out flex items-center justify-center"
          >
            Clear <span className="ml-2 font-semibold">All</span>
          </button>
        </div>
      </div>

      <div className="flex flex-row gap-5 ">
      <button
          onClick={handleSearch}
          className="w-full py-1 px-4 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out "
        >
          Search
        </button>
        <button
          onClick={exportToExcel}
          className="w-full py-1 px-4 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out "
        >
          Export to Excel
        </button>
      </div>
  
      <div className="flex items-center justify-center my-5 w-auto overflow-x-auto">
        {isLoading ? (
          <ClipLoader color="#1D56A5" size={50} />
        ) : (
          <div className=" w-full max-w-4xl mt-4 rounded-lg shadow-lg bg-white ">
            {noDataFound ? (
              <div className="text-center text-red-500 font-bold">
                Data not found
              </div>
            ) : (
              <table className="min-w-full  bg-white shadow-md rounded-2xl ">
                <thead className="w-full border border-[#4dc9ff] bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white p-10 rounded-full ">
                  <tr>
                    {[
                      "SL",
                      "NAME",
                      "STATE",
                      "SECTION",
                      "HONOURABLE_CHARGE_NO",
                      // "ISSUED_DATE",
                      "RENEWED_UPTO",
                      "STATUS",
                      "AGE",
                      "dob",
                      "EDUCATION_QUALIFICATION",
                      "Action",
                      "Update",
                    ].map((header, index) => (
                      <th
                        key={index}
                        className="px-8 py-2 text-xs font-semibold uppercase tracking-wide text-center"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {currentUsers.map((user, index) => (
                    <tr
                      key={user.id}
                      className="hover:bg-blue-50 transition duration-200 ease-in-out"
                    >
                      <td className="text-xs text-gray-800 text-center">
                        {indexOfFirstItem + index + 1}
                      </td>
                      <td className="text-xs text-gray-800 text-center">
                        {user.name}
                      </td>
                      <td className="text-xs text-gray-800 text-center">
                        {user.STATE}
                      </td>
                      <td className="text-xs text-gray-800 text-center">
                        {user.SECTION}
                      </td>
                      <td className="text-xs text-gray-800 text-center">
                        {user.HONOURABLE_CHARGE_NO}
                      </td>
                      {/* <td className="text-xs text-gray-800 text-center">
                        {user.DATE}
                      </td> */}
                      <td className="text-xs text-gray-800 text-center">
                        {user.RENEWED_UPTO}
                      </td>
                      <td className="text-xs text-gray-800 text-center">
                        {user.STATUS}
                      </td>
                      <td className="text-xs text-gray-800 text-center">
                        {calculateAge(user.dob)}
                      </td>
                      <td className="text-xs text-gray-800 text-center">
                        {user.dob}
                      </td>
                      <td className="text-xs text-gray-800 text-center">
                        {user.EDUCATION_QUALIFICATION}
                      </td>
                      <td className="text-xs text-gray-800 text-center">
                        <button
                          onClick={() => openModal(user)}
                          className="px-4 py-2 bg-blue-600 text-white text-xs rounded hover:bg-blue-700"
                        >
                          View <span className="px-1">All</span>
                        </button>
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        <button
                          onClick={() => openEditModal(user)}
                          className="px-4 py-2 bg-blue-600 text-white text-xs rounded hover:bg-blue-700"
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {selectedUser && (
                <div className="bg-white shadow-md rounded-lg p-6">
                  <h2 className="text-2xl font-bold text-gray-800 mb-6 border-b pb-2 uppercase">
                    User Details
                  </h2>
                  <div className="grid grid-cols-2 gap-4 text-gray-700">
                    <div className="flex">
                      <strong className="w-48 text-black">Name:</strong>
                      <span>{selectedUser.name}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">DOB:</strong>{" "}
                      <span>{selectedUser.dob}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">BSG UID:</strong>
                      <span>{selectedUser.bsgUid}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Email:</strong>
                      <span>{selectedUser.email}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Mobile:</strong>
                      <span>{selectedUser.MOBILE}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">State:</strong>
                      <span>{selectedUser.STATE}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Section:</strong>
                      <span>{selectedUser.SECTION}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">
                        Education Qualification:
                      </strong>
                      <span>{selectedUser.EDUCATION_QUALIFICATION}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">ROT No:</strong>
                      <span>{selectedUser.ROT}</span>
                    </div>
                    <div className="flex">
                      <strong className="w-48 text-black">Issued Date:</strong>
                      <span>{selectedUser.DATE}</span>
                    </div>
                  </div>
                  <button
                    onClick={closeModal}
                    className="mt-6 px-6 py-2 bg-[#1d56a5] hover:bg-blue-700 text-white rounded-sm transition-all duration-300 ease-in-out"
                  >
                    Close
                  </button>
                </div>
              )}
            </Modal>

            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                  width: "90%",
                  maxWidth: "500px",
                },
              }}
            >
              {editUser && (
                <div className=" shadow-md rounded-lg p-6">
                  <h2 className="text-xl font-bold text-gray-800 mb-4 border-b pb-2 uppercase">
                    Edit User Details
                  </h2>
                  <div className="  lg:grid-cols-2 grid grid-cols-1  gap-4 text-gray-700">
                    <div>
                      <label className="block text-black">Name:</label>
                      <input
                        type="text"
                        value={editUser.name}
                        onChange={(e) =>
                          setEditUser({ ...editUser, name: e.target.value })
                        }
                        className="w-full border border-[#4dc9ff]rounded-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-black">DOB:</label>
                      <input
                        type="date"
                        value={editUser.dob}
                        onChange={(e) =>
                          setEditUser({ ...editUser, dob: e.target.value })
                        }
                        className="w-full border border-[#4dc9ff]rounded-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-black">STATE:</label>
                      <input
                        type="text"
                        value={editUser.STATE}
                        onChange={(e) =>
                          setEditUser({ ...editUser, STATE: e.target.value })
                        }
                        className="w-full border border-[#4dc9ff]rounded-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-black">SECTION:</label>
                      <input
                        type="text"
                        value={editUser.SECTION}
                        onChange={(e) =>
                          setEditUser({ ...editUser, SECTION: e.target.value })
                        }
                        className="w-full border border-[#4dc9ff]rounded-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-black">
                        HONOURABLE_CHARGE_NO:
                      </label>
                      <input
                        type="text"
                        value={editUser.HONOURABLE_CHARGE_NO}
                        onChange={(e) =>
                          setEditUser({
                            ...editUser,
                            HONOURABLE_CHARGE_NO: e.target.value,
                          })
                        }
                        className="w-full border border-[#4dc9ff]rounded-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-black">ISSUED_DATE:</label>
                      <input
                        type="date"
                        value={editUser.ISSUED_DATE}
                        onChange={(e) =>
                          setEditUser({
                            ...editUser,
                            ISSUED_DATE: e.target.value,
                          })
                        }
                        className="w-full border border-[#4dc9ff]rounded-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-black">RENEWED_UPTO:</label>
                      <input
                        type="text"
                        value={editUser.RENEWED_UPTO}
                        onChange={(e) =>
                          setEditUser({
                            ...editUser,
                            RENEWED_UPTO: e.target.value,
                          })
                        }
                        className="w-full border border-[#4dc9ff]rounded-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-black">STATUS:</label>
                      <input
                        type="text"
                        value={editUser.STATUS}
                        onChange={(e) =>
                          setEditUser({ ...editUser, STATUS: e.target.value })
                        }
                        className="w-full border border-[#4dc9ff]rounded-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-black">
                        EDUCATION_QUALIFICATION:
                      </label>
                      <input
                        type="text"
                        value={editUser.EDUCATION_QUALIFICATION}
                        onChange={(e) =>
                          setEditUser({
                            ...editUser,
                            EDUCATION_QUALIFICATION: e.target.value,
                          })
                        }
                        className="w-full border border-[#4dc9ff]rounded-sm"
                      />
                    </div>
                  </div>
                  <button
                    onClick={handleUpdate}
                    className="mt-4 px-6 py-2 bg-[#1D56A5]  text-white rounded-sm transition-all duration-300 ease-in-out w-full"
                  >
                    Update
                  </button>
                  <button
                    onClick={closeModal}
                    className="mt-2 px-6 py-2 bg-black  text-white rounded-sm transition-all duration-300 ease-in-out w-full"
                  >
                    Close
                  </button>
                </div>
              )}
            </Modal>
          </div>
        )}
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="py-1 px-4 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out "
        >
          Previous
        </button>
        <span className="text-xs text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
            className="py-1 px-4 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out "
          >
            Next
        </button>
      </div>
    </>
  );
};

export default AltList;