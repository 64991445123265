// import React, { useEffect, useState, useRef } from "react";
// import axios from "axios";
// import ClipLoader from "react-spinners/ClipLoader";
// import Modal from "react-modal";
// import { MdOutlineKeyboardArrowDown } from "react-icons/md";
// import * as XLSX from "xlsx";
// import { BASE_URL } from "../../constant/constant";
// import { toast } from "react-toastify";
// import Select from "react-select";

// Modal.setAppElement("#root");
// const LtList = () => {
//   const [states, setStates] = useState([]);
//   const [status, setStatus] = useState([]);
//   const [selectedStatus, setSelectedStatus] = useState([]);
//   const [sections, setSections] = useState([]);
//   const [startDob, setStartDob] = useState("");
//   const [endDob, setEndDob] = useState("");
//   const [startissuedate, setStartIssueDate] = useState("");
//   const [endissuedate, setEndIssueDate] = useState("");
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedSection, setSelectedSection] = useState("");
//   const [uid, setUid] = useState("");
//   const [region, setRegion] = useState("");
//   const [email, setEmail] = useState("");
//   const [honourable, setHonourable] = useState("");
//   const [educationQualification, setEducationQualification] = useState([]);
//   const [selectedEducationQualification, setSelectedEducationQualification] =
//     useState("");
//   const [startparchmentNumber, setStartparchmentNumber] = useState("");
//   const [endparchmentNumber, setEndparchmentNumber] = useState("");
//   const [stateOptions, setStateOptions] = useState([]);
//   const [statusOptions, setStatusOptions] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [users, setUsers] = useState([]);
//   const [sectionOptions, setSectionOptions] = useState([]);
//   const [qualificationOptions, setQualificationOptions] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);
//   const [isOpen1, setIsOpen1] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 100;

//   const [stateSearchQuery, setStateSearchQuery] = useState("");
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [noDataFound, setNoDataFound] = useState(false);
//   const [editUser, setEditUser] = useState(null);
//   const token = localStorage.getItem("adminToken");
//   const axiosConfig = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//   };
//   const exportToExcel = () => {
//     const altDetailsData = filteredUsers.map((user) => {
//       const { __v, createdAt, updatedAt, _id, ...details } = user.altDetails;
//       return details;
//     });

//     const worksheet = XLSX.utils.json_to_sheet(altDetailsData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//     const excelBuffer = XLSX.write(workbook, {
//       bookType: "xlsx",
//       type: "array",
//     });
//     const data = new Blob([excelBuffer], {
//       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
//     });
//     const url = window.URL.createObjectURL(data);
//     const link = document.createElement("a");
//     link.href = url;
//     link.download = "filtered_data.xlsx";
//     link.click();
//     window.URL.revokeObjectURL(url);
//   };

//   const clearAll = () => {
//     setSelectedStates([]);
//     setSelectedStatus([]);
//     setStartDob("");
//     setEndDob("");
//     setStartIssueDate("");
//     setEndIssueDate("");
//     setUid("");
//     setEmail("");
//     setRegion("");
//     setHonourable("");
//     setSelectedEducationQualification("");
//     setStartparchmentNumber("");
//     setEndparchmentNumber("");
//     setSelectedSection("");
//     setCurrentPage(1);
//     setFilteredUsers(users);
//   };

//   const openModal = (user) => {
//     setSelectedUser(user);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedUser(null);
//   };

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const toggleDropdown1 = () => {
//     setIsOpen1(!isOpen1);
//   };

//   const openEditModal = (user) => {
//     setEditUser(user);
//     setIsModalOpen(true);
//   };

//   const handleUpdate = async () => {
//     try {
//       const response = await axios.put(
//         `${BASE_URL}/api/v1/ltuser/${editUser._id}`,
//         editUser,
//         axiosConfig
//       );

//       if (response.status === 200) {
//         toast.success("Data updated successfully");

//         const updatedUser = response.data;
//         setUsers(
//           users.map((user) => (user._id === editUser._id ? updatedUser : user))
//         );
//         setFilteredUsers(
//           filteredUsers.map((user) =>
//             user._id === editUser._id ? updatedUser : user
//           )
//         );

//         closeModal();
//       }
//     } catch (error) {
//       console.error("Error updating user:", error);
//       toast.error("Failed to update data. Please try again.");
//     }
//   };
//   const dropdownRef = useRef(null);
//   const dropdownRef1 = useRef(null);
//   const handleClickOutside1 = (event) => {
//     if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
//       setIsOpen1(false);
//     }
//   };

//   useEffect(() => {
//     getData();
//   }, []);

//   const getData = async () => {
//     try {
//       const response = await axios.get(
//         `${BASE_URL}/api/v1/ltuser`,
//         axiosConfig
//       );
//       const usersData = response.data;
//       if (Array.isArray(usersData) && usersData.length > 0) {
//         setUsers(usersData);
//         setFilteredUsers(usersData);
//         const uniqueSections = [
//           ...new Set(
//             usersData
//               .map((user) => user.SECTION?.trim().toLowerCase())
//               .filter(Boolean)
//           ),
//         ];

//         const uniqueQualifications = [
//           ...new Set(
//             usersData
//               .map((user) => user.EDUCATION_QUALIFICATION)
//               .filter(Boolean)
//           ),
//         ];

       
//         const sectionOptions = uniqueSections.map((section) => ({ value: section, label: section }));
//         const qualificationOptions = uniqueQualifications.map((qualification) => ({ value: qualification, label: qualification }));

//         setSectionOptions(sectionOptions);
//         setQualificationOptions(qualificationOptions);
//         const uniqueStates = [
//           ...new Set(
//             usersData
//               .map((user) => user.STATE?.trim().toLowerCase())
//               .filter(Boolean)
//           ),
//         ];
//         const uniqueStatus = [
//           ...new Set(usersData.map((user) => user.STATUS).filter(Boolean)),
//         ];
//         const stateOptions = uniqueStates.map((state) => ({
//           value: state,
//           label: state,
//         }));
//         const statusOptions = uniqueStatus.map((status) => ({
//           value: status,
//           label: status,
//         }));
//         setStateOptions(stateOptions);
//         setStatusOptions(statusOptions);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

 

//   const handleSectionChange = (selectedOptions) => {
//     setSelectedSection(selectedOptions.map(option => option.value));
//   };

//   const handleQualificationChange = (selectedOptions) => {
//     setSelectedEducationQualification(selectedOptions.map(option => option.value));
//   };

//   const handleStateChange = (selectedOptions) => {
//     setSelectedStates(selectedOptions.map((option) => option.value));
//   };

//   const handleStatusChange = (selectedOptions) => {
//     setSelectedStatus(selectedOptions.map((option) => option.value));
//   };

//   const handleClickOutside = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//       setIsOpen(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside1);
//     document.addEventListener("mousedown", handleClickOutside);

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside1);
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   useEffect(() => {
//     getData();
//   }, []);

//   const handleStateSelection = (state) => {
//     if (selectedStates.includes(state)) {
//       setSelectedStates(selectedStates.filter((s) => s !== state));
//     } else {
//       setSelectedStates([...selectedStates, state]);
//     }
//   };

//   const handleStatusSelection = (status) => {
//     if (selectedStatus.includes(status)) {
//       setSelectedStatus(selectedStatus.filter((s) => s !== status));
//     } else {
//       setSelectedStatus([...selectedStatus, status]);
//     }
//   };

//   const handleSearch = () => {
//     const filteredData = users.filter((item) => {
//       const issuedate = new Date(item.DATE);
//       const isIssueDate =
//         (!startissuedate || issuedate >= new Date(startissuedate)) &&
//         (!endissuedate || issuedate <= new Date(endissuedate));

//       const dob = new Date(item.dob);
//       const isDobInRange =
//         (!startDob || dob >= new Date(startDob)) &&
//         (!endDob || dob <= new Date(endDob));

//       const parchmentNumber = item.PARCHMENT_NO;
//       const isParchmentInRange =
//         (!startparchmentNumber || parchmentNumber >= startparchmentNumber) &&
//         (!endparchmentNumber || parchmentNumber <= endparchmentNumber);

//       const isStateSelected =
//         selectedStates.length === 0 ||
//         selectedStates.includes(item.STATE?.trim().toLowerCase());

//       const isStatusSelected =
//         selectedStatus.length === 0 || selectedStatus.includes(item.STATUS);

//       return (
//         isParchmentInRange &&
//         isIssueDate &&
//         isStateSelected &&
//         isStatusSelected &&
//         (startDob || endDob ? isDobInRange : true) &&
//         (startissuedate || endissuedate ? isDobInRange : true) &&
//         (uid ? item?.bsgUid === uid : true) &&
//         (region ? item.REGION === region : true) &&
//         (honourable ? item.HONOURABLE_CHARGE_NO === honourable : true) &&
//         (selectedEducationQualification
//           ? item.EDUCATION_QUALIFICATION === selectedEducationQualification
//           : true) &&
//         (email ? item?.email === email : true) &&
//         (startparchmentNumber || endparchmentNumber
//           ? isParchmentInRange
//           : true) &&
//         (selectedSection
//           ? item.SECTION?.trim().toLowerCase() === selectedSection
//           : true)
//       );
//     });
//     console.log(filteredData, "filteredData");
//     setIsOpen(false);
//     setFilteredUsers(filteredData.length > 0 ? filteredData : users);
//     setNoDataFound(filteredData.length === 0);
//   };
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

//   const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

//   const goToNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const goToPreviousPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const calculateAge = (dob) => {
//     const parts = dob?.split("-");
//     if (parts?.length === 3) {
//       const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
//       const birthDate = new Date(formattedDate);
//       if (isNaN(birthDate.getTime())) {
//         return "Invalid Date";
//       }

//       const today = new Date();
//       let age = today.getFullYear() - birthDate.getFullYear();
//       const monthDifference = today.getMonth() - birthDate.getMonth();

//       if (
//         monthDifference < 0 ||
//         (monthDifference === 0 && today.getDate() < birthDate.getDate())
//       ) {
//         age--;
//       }

//       return age;
//     } else {
//       return "Invalid Date";
//     }
//   };

//   return (
//     <>
//     <div className="text-2xl font-bold text-center text-gray-800 uppercase">
//         LT Records
//       </div>
//       <div className="text-2xl font-bold mb-4 pb-5 text-center text-gray-800 uppercase"></div>
//       <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-1 mb-6 ">
//         <div className="relative">
//           <label className="block text-md font-medium text-black uppercase">
//             State
//           </label>
//           <Select
//             isMulti
//             options={stateOptions}
//             onChange={handleStateChange}
//             className="basic-multi-select border border-[#4dc9ff] rounded-md"
//             classNamePrefix="select"
//             placeholder="Select States"
//             styles={{
//               control: (base) => ({
//                 ...base,
//                 minWidth: "200px",
//                 width: "100%",
//               }),
//               multiValue: (base) => ({
//                 ...base,
//                 padding: "2px 2px",
//                 fontSize: "10px",
//                 minHeight: "3px",
//               }),
//               multiValueLabel: (base) => ({
//                 ...base,
//                 fontSize: "10px",
//               }),
//               multiValueRemove: (base) => ({
//                 ...base,
//                 fontSize: "2px",
//               }),
//             }}
//           />
//         </div>

//         <div>
//           <label className="block text-md font-medium text-black  uppercase">
//             DOB (From)
//           </label>
//           <input
//             type="date"
//             value={startDob}
//             onChange={(e) => setStartDob(e.target.value)}
//          className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
//           />
//         </div>

//         <div>
//           <label className="block text-md font-medium text-black uppercase">
//             DOB (To)
//           </label>
//           <input
//             type="date"
//             value={endDob}
//             onChange={(e) => setEndDob(e.target.value)}
//           className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
//           />
//         </div>

//         <div>
//           <label className="block text-md font-medium text-black uppercase">
//             ISSUE DATE (From)
//           </label>
//           <input
//             type="date"
//             value={startissuedate}
//             onChange={(e) => setStartIssueDate(e.target.value)}
//         className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
//           />
//         </div>

//         <div>
//           <label className="block text-md font-medium text-black uppercase">
//             ISSUE DATE (To)
//           </label>
//           <input
//             type="date"
//             value={endissuedate}
//             onChange={(e) => setEndIssueDate(e.target.value)}
//            className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
//           />
//         </div>

//         <div className="flex flex-col">
//           <label className="block text-md font-medium  text-black uppercase">
//             Section
//           </label>
//           <Select
//             isMulti
//             options={sectionOptions}
//             onChange={handleSectionChange}
//             className="basic-multi-select border border-[#4dc9ff] rounded-md"
//             classNamePrefix="select"
//             placeholder="Select States"
//             styles={{
//               control: (base) => ({
//                 ...base,
//                 minWidth: "200px",
//                 width: "100%",
//               }),
//               multiValue: (base) => ({
//                 ...base,
//                 padding: "2px 2px",
//                 fontSize: "10px",
//                 minHeight: "3px",
//               }),
//               multiValueLabel: (base) => ({
//                 ...base,
//                 fontSize: "10px",
//               }),
//               multiValueRemove: (base) => ({
//                 ...base,
//                 fontSize: "2px",
//               }),
//             }}
//           />
//         </div>

//         <div className="">
//           <label className="block text-md font-medium text-black uppercase ">
//             Honourable No.
//           </label>
//           <input
//             type="email"
//             placeholder="Enter Number"
//             value={honourable}
//             onChange={(e) => setHonourable(e.target.value)}
//             className="w-full py-2 px-2 border border-[#4dc9ff]  text-sm rounded-md shadow-sm"
//           />
//         </div>

//         <div className="">
//           <label className="block text-md font-medium text-black uppercase ">
//             Qualification
//           </label>
//           <Select
//             isMulti
//             options={qualificationOptions}
//             onChange={handleQualificationChange}
//             className="basic-multi-select border border-[#4dc9ff] rounded-md"
//             classNamePrefix="select"
//             placeholder="Select States"
//             styles={{
//               control: (base) => ({
//                 ...base,
//                 minWidth: "200px",
//                 width: "100%",
//               }),
//               multiValue: (base) => ({
//                 ...base,
//                 padding: "2px 2px",
//                 fontSize: "10px",
//                 minHeight: "3px",
//               }),
//               multiValueLabel: (base) => ({
//                 ...base,
//                 fontSize: "10px",
//               }),
//               multiValueRemove: (base) => ({
//                 ...base,
//                 fontSize: "2px",
//               }),
//             }}
//           />
//         </div>

//         <div className="relative">
//           <label className="block text-md font-medium text-black uppercase">
//             Status
//           </label>
//           <Select
//             isMulti
//             options={statusOptions}
//             onChange={handleStatusChange}
//             className="basic-multi-select border border-[#4dc9ff] rounded-md"
//             classNamePrefix="select"
//             placeholder="Select States"
//             styles={{
//               control: (base) => ({
//                 ...base,
//                 minWidth: "200px",
//                 width: "100%",
//               }),
//               multiValue: (base) => ({
//                 ...base,
//                 padding: "2px 2px",
//                 fontSize: "10px",
//                 minHeight: "3px",
//               }),
//               multiValueLabel: (base) => ({
//                 ...base,
//                 fontSize: "10px",
//               }),
//               multiValueRemove: (base) => ({
//                 ...base,
//                 fontSize: "2px",
//               }),
//             }}
//           />
//         </div>

//         <div className="flex items-center justify-between flex-row">
//           <button
//             onClick={clearAll}
//             className="py-0 mt-[20px] px-6 h-10 bg-red-900 border border-[#ac5a5a] text-white text-sm font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out flex items-center justify-center"
//           >
//             Clear <span className="ml-1"> All</span>
//           </button>
//         </div>
//       </div>

//       <div className="flex flex-row gap-5 ">
//         <button
//           onClick={handleSearch}
//           className="w-full py-1 px-4 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out "
//         >
//           Search
//         </button>
//         <button
//           onClick={exportToExcel}
//           className="w-full py-1 px-4 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out "
//         >
//           Export to Excel
//         </button>
//       </div>

//       <div className="flex items-center justify-center my-5  ">
//         {isLoading ? (
//           <ClipLoader color="#1D56A5" size={50} />
//         ) : (
//           <div className="w-full max-w-xl mt-4 rounded-lg shadow-lg  bg-white lg:overflow-x-auto lg:max-w-3xl mx-auto overflow-hidden">
//             {noDataFound ? (
//               <div className="text-center text-red-500 font-bold">
//                 Data not found
//               </div>
//             ) : (
//               <table className="min-w-full bg-white shadow-md border border-[#4dc9ff] rounded-lg overflow-hidden">
//                 <thead className="w-full py-2 px-4 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out ">
//                   <tr>
//                     {[
//                       "SL",
//                       "NAME",
//                       "STATE",
//                       "SECTION",
//                       "HONOURABLE_CHARGE_NO",
//                       "ISSUED_DATE",
//                       "RENEWED_UPTO",
//                       "STATUS",
//                       "AGE",
//                       "DOB",
//                       "EDUCATION_QUALIFICATION",
//                       "Action",
//                       "Update",
//                     ].map((header, index) => (
//                       <th
//                         key={index}
//                         className="px-4 py-2 text-xs font-semibold uppercase tracking-wide text-center whitespace-nowrap"
//                       >
//                         {header}
//                       </th>
//                     ))}
//                   </tr>
//                 </thead>

//                 <tbody className="bg-white divide-y divide-gray-200">
//                   {currentUsers.map((user, index) => (
//                     <tr
//                       key={user.id}
//                       className="hover:bg-blue-50 transition duration-200 ease-in-out"
//                     >
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {indexOfFirstItem + index + 1}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {user.name}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {user.STATE}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {user?.SECTION}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {user.HONOURABLE_CHARGE_NO || user.honourableNumber}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {user.DATE || "NA"}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {user.RENEWED_UPTO}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {user.STATUS}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {calculateAge(user.dob)}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {user.dob}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         {user.EDUCATION_QUALIFICATION}
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         <button
//                           onClick={() => openModal(user)}
//                           className="px-4 py-2 bg-blue-600 text-white text-xs rounded hover:bg-blue-700 flex flex-row"
//                         >
//                           View <span className="px-1">All</span>
//                         </button>
//                       </td>
//                       <td className="px-4 py-2 text-xs text-gray-800 text-center">
//                         <button
//                           onClick={() => openEditModal(user)}
//                           className="px-4 py-2 bg-blue-600 text-white text-xs rounded hover:bg-blue-700"
//                         >
//                           Edit
//                         </button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             )}

//             <Modal
//               isOpen={isModalOpen}
//               onRequestClose={closeModal}
//               style={{
//                 overlay: {
//                   backgroundColor: "rgba(0, 0, 0, 0.5)",
//                 },
//                 content: {
//                   top: "50%",
//                   left: "50%",
//                   right: "auto",
//                   bottom: "auto",
//                   marginRight: "-50%",
//                   transform: "translate(-50%, -50%)",
//                   backgroundColor: "#fff",
//                   padding: "20px",
//                   border: "none",
//                   borderRadius: "10px",
//                   boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
//                   width: "90%",
//                   maxWidth: "500px",
//                 },
//               }}
//             >
//               {selectedUser && (
//                 <div className="bg-white shadow-md rounded-lg p-6">
//                   <h2 className="text-xl font-bold text-gray-800 mb-4 border-b pb-2 uppercase">
//                     User Details
//                   </h2>
//                   <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-700">
//                     <div>
//                       <strong className="block text-black">Name:</strong>
//                       <span>{selectedUser.name}</span>
//                     </div>
//                     <div>
//                       <strong className="block text-black">DOB:</strong>
//                       <span>{selectedUser.dob}</span>
//                     </div>
//                     <div>
//                       <strong className="block text-black">BSG UID:</strong>
//                       <span>{selectedUser.bsgUid}</span>
//                     </div>
//                     <div>
//                       <strong className="block text-black">Email:</strong>
//                       <span>{selectedUser.email}</span>
//                     </div>
//                     <div>
//                       <strong className="block text-black">Mobile:</strong>
//                       <span>{selectedUser.MOBILE}</span>
//                     </div>
//                     <div>
//                       <strong className="block text-black">State:</strong>
//                       <span>{selectedUser.STATE}</span>
//                     </div>
//                     <div>
//                       <strong className="block text-black">Section:</strong>
//                       <span>{selectedUser.SECTION}</span>
//                     </div>
//                     <div>
//                       <strong className="block text-black">
//                         Education Qualification:
//                       </strong>
//                       <span>{selectedUser.EDUCATION_QUALIFICATION}</span>
//                     </div>
//                     <div>
//                       <strong className="block text-black">ROT No:</strong>
//                       <span>{selectedUser.ROT}</span>
//                     </div>
//                     <div>
//                       <strong className="block text-black">Issued Date:</strong>
//                       <span>{selectedUser.DATE}</span>
//                     </div>
//                   </div>
//                   <button
//                     onClick={closeModal}
//                     className="mt-4 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-all duration-300 ease-in-out w-full"
//                   >
//                     Close
//                   </button>
//                 </div>
//               )}
//             </Modal>

//             <Modal
//               isOpen={isModalOpen}
//               onRequestClose={closeModal}
//               style={{
//                 overlay: {
//                   backgroundColor: "rgba(0, 0, 0, 0.5)",
//                 },
//                 content: {
//                   top: "50%",
//                   left: "50%",
//                   right: "auto",
//                   bottom: "auto",
//                   marginRight: "-50%",
//                   transform: "translate(-50%, -50%)",
//                   backgroundColor: "#fff",
//                   padding: "20px",
//                   border: "none",
//                   borderRadius: "10px",
//                   boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
//                   width: "90%",
//                   maxWidth: "500px",
//                 },
//               }}
//             >
//               {editUser && (
//                 <div className=" shadow-md rounded-lg p-6">
//                   <h2 className="text-xl font-bold text-gray-800 mb-4 border-b pb-2 uppercase">
//                     Edit User Details
//                   </h2>
//                   <div className="  lg:grid-cols-2 grid grid-cols-1  gap-4 text-gray-700">
//                     <div>
//                       <label className="block text-black">Name:</label>
//                       <input
//                         type="text"
//                         value={editUser.name}
//                         onChange={(e) =>
//                           setEditUser({ ...editUser, name: e.target.value })
//                         }
//                         className="w-full border border-gray-300 rounded-md"
//                       />
//                     </div>
//                     <div>
//                       <label className="block text-black">DOB:</label>
//                       <input
//                         type="date"
//                         value={editUser.dob}
//                         onChange={(e) =>
//                           setEditUser({ ...editUser, dob: e.target.value })
//                         }
//                         className="w-full border border-gray-300 rounded-md"
//                       />
//                     </div>
//                     <div>
//                       <label className="block text-black">STATE:</label>
//                       <input
//                         type="text"
//                         value={editUser.STATE}
//                         onChange={(e) =>
//                           setEditUser({ ...editUser, STATE: e.target.value })
//                         }
//                         className="w-full border border-gray-300 rounded-md"
//                       />
//                     </div>
//                     <div>
//                       <label className="block text-black">SECTION:</label>
//                       <input
//                         type="text"
//                         value={editUser.SECTION}
//                         onChange={(e) =>
//                           setEditUser({ ...editUser, SECTION: e.target.value })
//                         }
//                         className="w-full border border-gray-300 rounded-md"
//                       />
//                     </div>
//                     <div>
//                       <label className="block text-black">
//                         HONOURABLE_CHARGE_NO:
//                       </label>
//                       <input
//                         type="text"
//                         value={editUser.HONOURABLE_CHARGE_NO}
//                         onChange={(e) =>
//                           setEditUser({
//                             ...editUser,
//                             HONOURABLE_CHARGE_NO: e.target.value,
//                           })
//                         }
//                         className="w-full border border-gray-300 rounded-md"
//                       />
//                     </div>
//                     <div>
//                       <label className="block text-black">ISSUED_DATE:</label>
//                       <input
//                         type="date"
//                         value={editUser.ISSUED_DATE}
//                         onChange={(e) =>
//                           setEditUser({
//                             ...editUser,
//                             ISSUED_DATE: e.target.value,
//                           })
//                         }
//                         className="w-full border border-gray-300 rounded-md"
//                       />
//                     </div>
//                     <div>
//                       <label className="block text-black">RENEWED_UPTO:</label>
//                       <input
//                         type="text"
//                         value={editUser.RENEWED_UPTO}
//                         onChange={(e) =>
//                           setEditUser({
//                             ...editUser,
//                             RENEWED_UPTO: e.target.value,
//                           })
//                         }
//                         className="w-full border border-gray-300 rounded-md"
//                       />
//                     </div>
//                     <div>
//                       <label className="block text-black">STATUS:</label>
//                       <input
//                         type="text"
//                         value={editUser.STATUS}
//                         onChange={(e) =>
//                           setEditUser({ ...editUser, STATUS: e.target.value })
//                         }
//                         className="w-full border border-gray-300 rounded-md"
//                       />
//                     </div>
//                     <div>
//                       <label className="block text-black">
//                         EDUCATION_QUALIFICATION:
//                       </label>
//                       <input
//                         type="text"
//                         value={editUser.EDUCATION_QUALIFICATION}
//                         onChange={(e) =>
//                           setEditUser({
//                             ...editUser,
//                             EDUCATION_QUALIFICATION: e.target.value,
//                           })
//                         }
//                         className="w-full border border-gray-300 rounded-md"
//                       />
//                     </div>
//                   </div>
//                   <button
//                     onClick={handleUpdate}
//                     className="mt-4 px-6 py-2 bg-[#1D56A5]  text-white rounded-md transition-all duration-300 ease-in-out w-full"
//                   >
//                     Update
//                   </button>
//                   <button
//                     onClick={closeModal}
//                     className="mt-2 px-6 py-2 bg-black  text-white rounded-md transition-all duration-300 ease-in-out w-full"
//                   >
//                     Close
//                   </button>
//                 </div>
//               )}
//             </Modal>
//           </div>
//         )}
//       </div>
//       <div className="flex flex-col sm:flex-row justify-between items-center mt-4 gap-4">
//         <button
//           onClick={goToPreviousPage}
//           disabled={currentPage === 1}
//           className="px-4 py-2 m-5 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 ease-in-out text-xs  hover:bg-blue-700 disabled:opacity-50 transition duration-200"
//         >
//           Previous
//         </button>
//         <span className="text-xs text-gray-700">
//           Page {currentPage} of {totalPages}
//         </span>
//         <button
//           onClick={goToNextPage}
//           disabled={currentPage === totalPages}
//           className="px-4 py-2 m-5 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 ease-in-out text-xs  hover:bg-blue-700 disabled:opacity-50 transition duration-200"
//         >
//           Next
//         </button>
//       </div>
//     </>
//   );
// };

// export default LtList;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "react-modal";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import * as XLSX from "xlsx";
import { BASE_URL } from "../../constant/constant";
import { toast } from "react-toastify";
import Select from "react-select";

Modal.setAppElement("#root");

const LtList = () => {
  const [states, setStates] = useState([]);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [sections, setSections] = useState([]);
  const [startDob, setStartDob] = useState("");
  const [endDob, setEndDob] = useState("");
  const [startissuedate, setStartIssueDate] = useState("");
  const [endissuedate, setEndIssueDate] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [uid, setUid] = useState("");
  const [region, setRegion] = useState("");
  const [email, setEmail] = useState("");
  const [honourable, setHonourable] = useState("");
  const [educationQualification, setEducationQualification] = useState([]);
  const [selectedEducationQualification, setSelectedEducationQualification] = useState([]);
  const [startparchmentNumber, setStartparchmentNumber] = useState("");
  const [endparchmentNumber, setEndparchmentNumber] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
    const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [editUser , setEditUser ] = useState(null);
  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const exportToExcel = () => {
    // Check if there are any filtered users to export
    if (filteredUsers.length === 0) {
      toast.warn("No data available to export.");
      return;
    }
  
    // Map the filtered users to extract the necessary details
    const altDetailsData = filteredUsers.map((user) => {
      // Check if altDetails is defined
      const { __v, createdAt, updatedAt, _id, altDetails = {}, ...details } = user;
      
      // Combine details and altDetails if needed
      return { ...details, ...altDetails }; // This will safely merge details and altDetails
    });
  
    // Create a worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(altDetailsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
    // Write the workbook to a binary array
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
  
    // Create a Blob from the binary data
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
  
    // Create a URL for the Blob and trigger a download
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.download = "filtered_data.xlsx"; // Set the file name
    document.body.appendChild(link); // Append the link to the body
    link.click(); // Trigger the download
    document.body.removeChild(link); // Remove the link after download
    window.URL.revokeObjectURL(url); // Release the Blob URL
  };

  const clearAll = () => {
    setSelectedStates([]);
    setSelectedStatus([]);
    setStartDob("");
    setEndDob("");
    setStartIssueDate("");
    setEndIssueDate("");
    setUid("");
    setEmail("");
    setRegion("");
    setHonourable("");
    setSelectedEducationQualification([]);
    setStartparchmentNumber("");
    setEndparchmentNumber("");
    setSelectedSection([]);
    setFilteredUsers(users);
  };

  

  const closeModal = () => {
    setIsModalOpen(false);
    setEditUser (null);
  };
    const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };



//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const toggleDropdown1 = () => {
//     setIsOpen1(!isOpen1);
//   };

  const openEditModal = (user) => {
    setEditUser(user);
    setIsModalOpen(true);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/v1/ltuser/${editUser ._id}`,
        editUser ,
        axiosConfig
      );

      if (response.status === 200) {
        toast.success("Data updated successfully");

        const updatedUser  = response.data;
        setUsers(users.map((user) => (user._id === editUser ._id ? updatedUser  : user)));
        setFilteredUsers(filteredUsers.map((user) => (user._id === editUser ._id ? updatedUser  : user)));

        closeModal();
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update data. Please try again.");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/ltuser`, axiosConfig);
      const usersData = response.data;

      if (Array.isArray(usersData) && usersData.length > 0) {
        setUsers(usersData);
        setFilteredUsers(usersData);

        const uniqueSections = [...new Set(usersData.map((user) => user.SECTION?.trim().toLowerCase()).filter(Boolean))];
        const uniqueQualifications = [...new Set(usersData.map((user) => user.EDUCATION_QUALIFICATION).filter(Boolean))];

        setSectionOptions(uniqueSections.map((section) => ({ value: section, label: section })));
        setQualificationOptions(uniqueQualifications.map((qualification) => ({ value: qualification, label: qualification })));

        const uniqueStates = [...new Set(usersData.map((user) => user.STATE?.trim().toLowerCase()).filter(Boolean))];
        const uniqueStatus = [...new Set(usersData.map((user) => user.STATUS).filter(Boolean))];

        setStateOptions(uniqueStates.map((state) => ({ value: state, label: state })));
        setStatusOptions(uniqueStatus.map((status) => ({ value: status, label: status })));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSectionChange = (selectedOptions) => {
    setSelectedSection(selectedOptions.map(option => option.value));
  };

  const handleQualificationChange = (selectedOptions) => {
    setSelectedEducationQualification(selectedOptions.map(option => option.value));
  };

  const handleStateChange = (selectedOptions) => {
    setSelectedStates(selectedOptions.map((option) => option.value));
  };

  const handleStatusChange = (selectedOptions) => {
    setSelectedStatus(selectedOptions.map((option) => option.value));
  };

  const handleSearch = () => {
    const filteredData = users.filter((item) => {
      const issuedate = new Date(item.DATE);
      const isIssueDate =
        (!startissuedate || issuedate >= new Date(startissuedate)) &&
        (!endissuedate || issuedate <= new Date(endissuedate));

      const dob = new Date(item.dob);
      const isDobInRange =
        (!startDob || dob >= new Date(startDob)) &&
        (!endDob || dob <= new Date(endDob));

      const parchmentNumber = item.PARCHMENT_NO;
      const isParchmentInRange =
        (!startparchmentNumber || parchmentNumber >= startparchmentNumber) &&
        (!endparchmentNumber || parchmentNumber <= endparchmentNumber);

      const isStateSelected =
        selectedStates.length === 0 ||
        selectedStates.includes(item.STATE?.trim().toLowerCase());

      const isStatusSelected =
        selectedStatus.length === 0 || selectedStatus.includes(item.STATUS);

      return (
        isParchmentInRange &&
        isIssueDate &&
        isStateSelected &&
        isStatusSelected &&
        (startDob || endDob ? isDobInRange : true) &&
        (uid ? item?.bsgUid === uid : true) &&
        (region ? item.REGION === region : true) &&
        (honourable ? item.HONOURABLE_CHARGE_NO === honourable : true) &&
        (selectedEducationQualification.length > 0
          ? selectedEducationQualification.includes(item.EDUCATION_QUALIFICATION)
          : true) &&
        (email ? item?.email === email : true) &&
        (selectedSection.length > 0
          ? selectedSection.includes(item.SECTION?.trim().toLowerCase())
          : true)
      );
    });

    setFilteredUsers(filteredData);
    setNoDataFound(filteredData.length === 0);
  };
    const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

    const calculateAge = (dob) => {
    const parts = dob?.split("-");
    if (parts?.length === 3) {
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      const birthDate = new Date(formattedDate);
      if (isNaN(birthDate.getTime())) {
        return "Invalid Date";
      }

      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    } else {
      return "Invalid Date";
    }
  };

  return (
    <>
      <div className="text-2xl font-bold text-center text-gray-800 uppercase">
        LT Records
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-1 mb-6 ">
        <div className="relative">
          <label className="block text-md font-medium text-black uppercase">
            State
          </label>
          <Select
            isMulti
            options={stateOptions}
            onChange={handleStateChange}
            className="basic-multi-select border border-[#4dc9ff] rounded-md"
            classNamePrefix="select"
            placeholder="Select States"
          />
        </div>

        <div>
          <label className="block text-md font-medium text-black uppercase">
            DOB (From)
          </label>
          <input
            type="date"
            value={startDob}
            onChange={(e) => setStartDob(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff] text-sm rounded-md shadow-sm"
          />
        </div>

        <div>
          <label className="block text-md font-medium text-black uppercase">
            DOB (To)
          </label>
          <input
            type="date"
            value={endDob}
            onChange={(e) => setEndDob(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff] text-sm rounded-md shadow-sm"
          />
        </div>

        <div>
          <label className="block text-md font-medium text-black uppercase">
            ISSUE DATE (From)
          </label>
          <input
            type="date"
            value={startissuedate}
            onChange={(e) => setStartIssueDate(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff] text-sm rounded-md shadow-sm"
          />
        </div>

        <div>
          <label className="block text-md font-medium text-black uppercase">
            ISSUE DATE (To)
          </label>
          <input
            type="date"
            value={endissuedate}
            onChange={(e) => setEndIssueDate(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff] text-sm rounded-md shadow-sm"
          />
        </div>

        <div className="flex flex-col">
          <label className="block text-md font-medium text-black uppercase">
            Section
          </label>
          <Select
            isMulti
            options={sectionOptions}
            onChange={handleSectionChange}
            className="basic-multi-select border border-[#4dc9ff] rounded-md"
            classNamePrefix="select"
            placeholder="Select Sections"
          />
        </div>

        <div>
          <label className="block text-md font-medium text-black uppercase">
            Honourable No.
          </label>
          <input
            type="text"
            placeholder="Enter Number"
            value={honourable}
            onChange={(e) => setHonourable(e.target.value)}
            className="w-full py-2 px-2 border border-[#4dc9ff] text-sm rounded-md shadow-sm"
          />
        </div>

        <div>
          <label className="block text-md font-medium text-black uppercase">
            Qualification
          </label>
          <Select
            isMulti
            options={qualificationOptions}
            onChange={handleQualificationChange}
            className="basic-multi-select border border-[#4dc9ff] rounded-md"
            classNamePrefix="select"
            placeholder="Select Qualifications"
          />
        </div>

        <div className="relative">
          <label className="block text-md font-medium text-black uppercase">
            Status
          </label>
          <Select
            isMulti
            options={statusOptions}
            onChange={handleStatusChange}
            className="basic-multi-select border border-[#4dc9ff] rounded-md"
            classNamePrefix="select"
            placeholder="Select Status"
          />
        </div>

        <div className="flex items-center justify-between flex-row">
          <button
            onClick={clearAll}
            className="py-0 mt-[20px] px-6 h-10 bg-red-900 border border-[#ac5a5a] text-white text-sm font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out flex items-center justify-center"
          >
            Clear <span className="ml-1"> All</span>
          </button>
        </div>
      </div>

      <div className="flex flex-row gap-5 ">
        <button
          onClick={handleSearch}
          className="w-full py-1 px-4 border border-[#4dc9ff] bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out"
        >
          Search
        </button>
        <button
          onClick={exportToExcel}
          className="w-full py-1 px-4 border border-[#4dc9ff] bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out"
        >
          Export to Excel
        </button>
      </div>

      <div className="flex items-center justify-center w-auto my-5">
        {isLoading ? (
          <ClipLoader color="#1D56A5" size={50} />
        ) : (
          <div className="w-full max-w-xl mt-4 rounded-lg shadow-lg bg-white lg:overflow-x-auto lg:max-w-3xl mx-auto overflow-hidden">
            {noDataFound ? (
              <div className="text-center text-red-500 font-bold">
                Data not found
              </div>
            ) : (
              <table className="min-w-full bg-white shadow-md border border-[#4dc9ff] rounded-lg overflow-hidden">
                <thead className="w-full py-2 px-4 border border-[#4dc9ff] bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white font-medium rounded-md shadow-md transform active:scale-95 transition-all duration-300 ease-in-out">
                  <tr>
                    {[
                      "SL",
                      "NAME",
                      "STATE",
                      "SECTION",
                      "HONOURABLE_CHARGE_NO",
                      "ISSUED_DATE",
                      "RENEWED_UPTO",
                      "STATUS",
                      "AGE",
                      "DOB",
                      "EDUCATION_QUALIFICATION",
                      "Action",
                      "Update",
                    ].map((header, index) => (
                      <th
                        key={index}
                        className="px-4 py-2 text-xs font-semibold uppercase tracking-wide text-center whitespace-nowrap"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {currentUsers.map((user, index) => (
                    <tr
                      key={user.id}
                      className="hover:bg-blue-50 transition duration-200 ease-in-out"
                    >
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {index + 1}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {user.name}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {user.STATE}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {user?.SECTION}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {user.HONOURABLE_CHARGE_NO || user.honourableNumber}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {user.DATE || "NA"}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {user.RENEWED_UPTO}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {user.STATUS}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {calculateAge(user.dob)}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {user.dob}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        {user.EDUCATION_QUALIFICATION}
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        <button
                          onClick={() => openModal(user)}
                          className="px-4 py-2 bg-blue-600 text-white text-xs rounded hover:bg-blue-700 flex flex-row"
                        >
                          View <span className="px-1">All</span>
                        </button>
                      </td>
                      <td className="px-4 py-2 text-xs text-gray-800 text-center">
                        <button
                          onClick={() => openEditModal(user)}
                          className="px-4 py-2 bg-blue-600 text-white text-xs rounded hover:bg-blue-700"
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}

                  
                </tbody>
              </table>
            )}

            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                  width: "90%",
                  maxWidth: "500px",
                },
              }}
            >
              {selectedUser && (
                <div className="bg-white shadow-md rounded-lg p-6">
                  <h2 className="text-xl font-bold text-gray-800 mb-4 border-b pb-2 uppercase">
                    User Details
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-700">
                    <div>
                      <strong className="block text-black">Name:</strong>
                      <span>{selectedUser.name}</span>
                    </div>
                    <div>
                      <strong className="block text-black">DOB:</strong>
                      <span>{selectedUser.dob}</span>
                    </div>
                    <div>
                      <strong className="block text-black">BSG UID:</strong>
                      <span>{selectedUser.bsgUid}</span>
                    </div>
                    <div>
                      <strong className="block text-black">Email:</strong>
                      <span>{selectedUser.email}</span>
                    </div>
                    <div>
                      <strong className="block text-black">Mobile:</strong>
                      <span>{selectedUser.MOBILE}</span>
                    </div>
                    <div>
                      <strong className="block text-black">State:</strong>
                      <span>{selectedUser.STATE}</span>
                    </div>
                    <div>
                      <strong className="block text-black">Section:</strong>
                      <span>{selectedUser.SECTION}</span>
                    </div>
                    <div>
                      <strong className="block text-black">
                        Education Qualification:
                      </strong>
                      <span>{selectedUser.EDUCATION_QUALIFICATION}</span>
                    </div>
                    <div>
                      <strong className="block text-black">ROT No:</strong>
                      <span>{selectedUser.ROT}</span>
                    </div>
                    <div>
                      <strong className="block text-black">Issued Date:</strong>
                      <span>{selectedUser.DATE}</span>
                    </div>
                  </div>
                  <button
                    onClick={closeModal}
                    className="mt-4 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-all duration-300 ease-in-out w-full"
                  >
                    Close
                  </button>
                </div>
              )}
            </Modal>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  border: "none",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                  width: "90%",
                  maxWidth: "500px",
                },
              }}
            >
              {editUser  && (
                <div className="shadow-md rounded-lg p-6">
                  <h2 className="text-xl font-bold text-gray-800 mb-4 border-b pb-2 uppercase">
                    Edit User Details
                  </h2>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 text-gray-700">
                    <div>
                      <label className="block text-black">Name:</label>
                      <input
                        type="text"
                        value={editUser .name}
                        onChange={(e) =>
                          setEditUser ({ ...editUser , name: e.target.value })
                        }
                        className="w-full border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-black">DOB:</label>
                      <input
                        type="date"
                        value={editUser .dob}
                        onChange={(e) =>
                          setEditUser ({ ...editUser , dob: e.target.value })
                        }
                        className="w-full border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-black">STATE:</label>
                      <input
                        type="text"
                        value={editUser .STATE}
                        onChange={(e) =>
                          setEditUser ({ ...editUser , STATE: e.target.value })
                        }
                        className="w-full border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-black">SECTION:</label>
                      <input
                        type="text"
                        value={editUser .SECTION}
                        onChange={(e) =>
                          setEditUser ({ ...editUser , SECTION: e.target.value })
                        }
                        className="w-full border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-black">HONOURABLE_CHARGE_NO:</label>
                      <input
                        type="text"
                        value={editUser .HONOURABLE_CHARGE_NO}
                        onChange={(e) =>
                          setEditUser ({
                            ...editUser ,
                            HONOURABLE_CHARGE_NO: e.target.value,
                          })
                        }
                        className="w-full border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-black">ISSUED_DATE:</label>
                      <input
                        type="date"
                        value={editUser .ISSUED_DATE}
                        onChange={(e) =>
                          setEditUser ({
                            ...editUser ,
                            ISSUED_DATE: e.target.value,
                          })
                        }
                        className="w-full border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-black">RENEWED_UPTO:</label>
                      <input
                        type="text"
                        value={editUser .RENEWED_UPTO}
                        onChange={(e) =>
                          setEditUser ({
                            ...editUser ,
                            RENEWED_UPTO: e.target.value,
                          })
                        }
                        className="w-full border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-black">STATUS:</label>
                      <input
                        type="text"
                        value={editUser .STATUS}
                        onChange={(e) =>
                          setEditUser ({ ...editUser , STATUS: e.target.value })
                        }
                        className="w-full border border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label className="block text-black">EDUCATION_QUALIFICATION:</label>
                      <input
                        type="text"
                        value={editUser .EDUCATION_QUALIFICATION}
                        onChange={(e) =>
                          setEditUser ({
                            ...editUser ,
                            EDUCATION_QUALIFICATION: e.target.value,
                          })
                        }
                        className="w-full border border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <button
                    onClick={handleUpdate}
                    className="mt-4 px-6 py-2 bg-[#1D56A5] text-white rounded-md transition-all duration-300 ease-in-out w-full"
                  >
                    Update
                  </button>
                  <button
                    onClick={closeModal}
                    className="mt-2 px-6 py-2 bg-black text-white rounded-md transition-all duration-300 ease-in-out w-full"
                  >
                    Close
                  </button>
                </div>
              )}
            </Modal>
          </div>
        )}
      </div>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 m-5 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 ease-in-out text-xs  hover:bg-blue-700 disabled:opacity-50 transition duration-200"
        >
          Previous
        </button>
        <span className="text-xs text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 m-5 border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium rounded-md shadow-md transform active:scale-95 ease-in-out text-xs  hover:bg-blue-700 disabled:opacity-50 transition duration-200"
        >
          Next
        </button>
      </div>
    </>
  );
};

export default LtList;