import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { BASE_URL } from "../../constant/constant";

const AddBSGState = () => {
  const [district, setDistrict] = useState("");
  const [states, setStates] = useState([]);

  const handleSubmit = async () => {
    const data = { district };
    try {
      const userId = localStorage.getItem("id");
      console.log(userId, "userId");
      const response = await axios.post(
        `${BASE_URL}/api/v1/addrevenuedistrict/${userId}`,
        data
      );
      console.log(response.data);
      toast.success("Revenue District Added Successfully");
      setDistrict("");
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getState();
  }, []);

  const getState = async () => {
    try {
      const userId = localStorage.getItem("id");
      console.log(userId, "userId");
      const response = await axios.get(
        `${BASE_URL}/api/v1/getrevenuedistrict/${userId}`
      );
      console.log(response, "response");
      console.log(response.data);
      setStates(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log(id, "id");
      const response = await fetch(
        `${BASE_URL}/api/v1/deleterevenuedistrict/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        console.log("Category deleted successfully");
        getState();
      } else {
        console.error("Failed to delete category:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <div className="bg-slate-100 h-screen">
      <div className=" pt-1 flex items-center justify-center ">
        <div className="bg-white p-8 rounded-lg shadow-lg  border border-[#4dc9ff]">
          <h1 className="text-2xl font-bold mb-4 text-center text-gray-800">
            ADD REVENUE DISTRICT
          </h1>
          <ToastContainer />
          <input
            type="text"
            placeholder="Enter the Revenue District"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
            className="border border-[#4dc9ff]   rounded-md w-full p-2 mb-4"
          />
          <button
            onClick={handleSubmit}
            className="w-full border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] transform active:scale-95 ease-in-out  disabled:opacity-50 transition duration-200 text-white px-5 py-2 rounded-lg shadow-lg  hover:bg-blue-600 "
          >
            Submit
          </button>
        </div>
      </div>

      <div className="font-bold text-2xl text-black  uppercase mt-6">
        All Revenue District
      </div>
      <div className="py-5">
        {states.map((item, index) => {
          return (
            <div
              key={index}
              className="flex justify-between items-center p-2 border border-[#4dc9ff] bg-white rounded-lg shadow-md  transition"
            >
              <div className="text-md text-gray-700">{item.district}</div>
              <button
                className="text-red-500 hover:text-red-700 transition"
                onClick={() => handleDelete(item._id)}
              >
                <MdDelete
                  style={{
                    fontSize: 24,
                  }}
                />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddBSGState;
