import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../constant/constant";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx"; // Import XLSX for Excel export

const KytFeedback = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userStatus, setUserStatus] = useState({});
  const [selectedSection, setSelectedSection] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const fetchFeedback = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/v2/getfeedback`);
      const feedbackList = response.data.data || [];

      const statusMap = feedbackList.reduce((acc, feedback) => {
        acc[feedback._id] = feedback.kytfeedbackstatus || "pending";
        return acc;
      }, {});

      setFeedbackData(feedbackList);
      setFilteredData(feedbackList);
      setUserStatus(statusMap);
    } catch (err) {
      setError("Failed to fetch feedback data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeedback();
  }, []);

  useEffect(() => {
    if (selectedSection) {
      setFilteredData(feedbackData.filter((fb) => fb.feedbackSection === selectedSection));
    } else {
      setFilteredData(feedbackData);
    }
  }, [selectedSection, feedbackData]);

  const handleFilterChange = (e) => {
    setSelectedSection(e.target.value);
  };

  const updateStatus = async (userId, newStatus) => {
    try {
      const endpoint =
        newStatus === "pending"
          ? `${BASE_URL}/api/v2/handlepending/${userId}`
          : `${BASE_URL}/api/v2/handleresolve/${userId}`;

      const response = await axios.put(endpoint);
      if (response.data.success) {
        alert(`Feedback has been marked as ${newStatus}`);
        fetchFeedback();
      }
    } catch (err) {
      alert("Failed to update feedback status. Please try again.");
    }
  };

  // Function to download data in Excel format
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredData.map((fb, index) => ({
        "S.No": index + 1,
        Name: fb.feedbackName,
        "Date of Birth": fb.feedbackDob,
        Email: fb.feedbackEmail,
        Wing: fb.feedbackWing || "NA",
        Section: fb.feedbackSection,
        "Honourable Charge Number": fb.feedbackHonuorable,
        "BSG UID": fb.feedbackUid,
        Issue: fb.issue,
        "Contact Number": fb.feedbackNumber,
        Status: userStatus[fb._id] || "Pending",
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "KYT Feedback");

    XLSX.writeFile(workbook, "KYT_Feedback_Report.xlsx");
  };

  const functionDate = (dateString) => {
    console.log(dateString,"DATAsTRING")
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  return (
    <div className="max-w-7xl mx-auto p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6 uppercase">
        KYT Feedback
      </h1>

      <div className="flex justify-between mb-4">
        <select className="p-2 border rounded" value={selectedSection} onChange={handleFilterChange}>
          <option value="">All Sections</option>
          {[...new Set(feedbackData.map((fb) => fb.feedbackSection))].map((section, idx) => (
            <option key={idx} value={section}>
              {section}
            </option>
          ))}
        </select>
        <div className="flex gap-2">
          <button onClick={downloadExcel} className="bg-green-500 text-white px-4 py-2 rounded">
            Download Excel
          </button>
        </div>
      </div>

      {loading && <p className="text-center text-gray-600">Loading feedback...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}

      {!loading && filteredData.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredData.map((feedback, index) => (
            <div
              key={feedback._id}
              className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-200 ease-in-out text-sm"
            >
              <p>
                <strong>S.NO:</strong> {index + 1}
              </p>
              <p className="text-gray-700">
                <strong className="text-gray-900">Name:</strong> {feedback.feedbackName}
              </p>
              <p className="text-gray-700">
                <strong className="text-gray-900">Date of Birth:</strong> {functionDate(feedback.feedbackDob)}
              </p>
              <p className="text-gray-700">
                <strong className="text-gray-900">Email:</strong> {feedback.feedbackEmail}
              </p>
              <p className="text-gray-700">
                <strong className="text-gray-900">Wing:</strong> {feedback.feedbackWing || "NA"}
              </p>
              <p className="text-gray-700">
                <strong className="text-gray-900">Section:</strong> {feedback.feedbackSection}
              </p>
              <p className="text-gray-700">
                <strong className="text-gray-900">Honourable Charge Number:</strong>{" "}
                {feedback.feedbackHonuorable}
              </p>
              <p className="text-gray-700">
                <strong className="text-gray-900">BSG UID:</strong> {feedback.feedbackUid}
              </p>
              <p className="text-gray-700">
                <strong className="text-gray-900">Issue:</strong>{" "}
                <span className="text-red-600">{feedback.issue}</span>
              </p>
              <p className="text-gray-700">
                <strong className="text-gray-900">Contact Number:</strong>{" "}
                {feedback.feedbackNumber}
              </p>
              <div className="mt-4 flex space-x-2">
                <button
                  className={`px-4 py-2 rounded text-white ${
                    userStatus[feedback._id] === "pending"
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-red-500 hover:bg-red-600"
                  }`}
                  onClick={() => updateStatus(feedback._id, "pending")}
                  disabled={userStatus[feedback._id] === "pending"}
                >
                  {userStatus[feedback._id] === "pending" ? "Pending" : "Mark as Pending"}
                </button>
                <button
                  className={`px-4 py-2 rounded text-white ${
                    userStatus[feedback._id] === "resolved"
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-green-500 hover:bg-green-600"
                  }`}
                  onClick={() => updateStatus(feedback._id, "resolved")}
                  disabled={userStatus[feedback._id] === "resolved"}
                >
                  {userStatus[feedback._id] === "resolved" ? "Resolved" : "Mark as Resolved"}
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        !loading && <p className="text-center text-gray-600">No feedback available.</p>
      )}
    </div>
  );
};

export default KytFeedback;
