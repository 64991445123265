import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../constant/constant";
import Select from "react-select";
import Modal from "react-modal";
import * as XLSX from "xlsx";
Modal.setAppElement("#root");

const ALtApplicationData = () => {
  const [status, setStatus] = useState({});
  const [ntcStatus, setNtcStatus] = useState({});
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // Store selected user
  const [scorecard, setScorecard] = useState(null);
 const [role, setRole] = useState(null);
  const [assignment, setAssignment] = useState(null);




    useEffect(() => {
      const localAssignment = localStorage.getItem("assignment");
      const localRole = localStorage.getItem("role");
      console.log(localRole,"role")
      setRole(localRole)
  console.log(localAssignment,"localAssignment")
      if (localAssignment || localRole) {
        try {
          setAssignment(JSON.parse(localAssignment)); 
          setRole(JSON.parse(localRole)); 
          // Parse if stored as JSON
        } catch (error) {
          console.error("Error parsing assignment from localStorage:", error);
       // Store as string if parsing fails
        }
      }
    }, []);
  useEffect(() => {
    getALtApplicationData();
  }, []);

  const getALtApplicationData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/kytuser/getAllaltapplicationData`
      );
      console.log(response.data.data, "data");
      if (response?.data.success) {
        setData(response.data.data);

        // Mapping statuses
        const userStatusMap = response.data.data.reduce((acc, item) => {
          acc[item.userId] = item.status || "Pending";
          return acc;
        }, {});

        const ntcStatusMap = response.data.data.reduce((acc, item) => {
          acc[item.userId] = item.ntcstatus || "Pending";
          return acc;
        }, {});

        setStatus(userStatusMap);
        setNtcStatus(ntcStatusMap);
      } else {
        setStatus({});
        setNtcStatus({});
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updateApplicationStatus = async (userId, newStatus) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/kytuser/updateltapplicationStatus/${userId}`,
        { status: newStatus }
      );

      if (response.data.success) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          [userId]: newStatus,
        }));

        console.log("Status updated successfully");
        // getLtApplicationData();
      } else {
        console.log("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleSubmitScorecard = async (Bsguid) => {
    if (!scorecard) {
      alert("Please upload a scorecard.");
      return;
    }
    console.log(Bsguid, "BSGuid");

    const formData = new FormData();
    formData.append("scorecard", scorecard);
    formData.append("Bsguid", Bsguid); // Append Bsguid to formData

    try {
      const response = await axios.post(
        `${BASE_URL}/api/kytuser/scorecard`,
        formData, // Send formData directly
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data, "data");
      if (response.data.result === "pass") {
        alert("Scorecard uploaded and Its Pass.");
      } else {
        alert("Scorecard uploaded and Its Fail");
      }
    } catch (error) {
      console.error("Error uploading scorecard:", error);
      alert("An error occurred while uploading the scorecard.");
    }
  };

  const downloadExcel = () => {
    const formattedData = data.map((item) => ({
      "Candidate Name": item.candidateName,
      Email: item.email,
      BSGUID: item.Bsguid,
      Wing: item.wing,
      "Date of Birth": item.dateOfBirth,
      Score: item.score,
      "Pass/Fail": item.passFail,
      Recommendation: item.recommendation,
    }));

    // Create a worksheet from the formatted data
    const ws = XLSX.utils.json_to_sheet(formattedData);

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Scorecard Data");

    // Export the file as an Excel (.xlsx)
    XLSX.writeFile(wb, "Candidate_Scorecards.xlsx");
  };
  const functionDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const downloadLtExcelUpload = async (selectedUser) => {
    if (!selectedUser) {
      alert("Please select a user to download data.");
      return;
    }

    try {
      // Formatting selected user data
      const userData = [
        {
          SL: "1",
          name: selectedUser.candidateName,
          email: selectedUser.email,
          bsgUid: selectedUser.Bsguid,
          SECTION: selectedUser.wing,
          dob: functionDate(selectedUser.dateOfBirth),
          ISSUED_DATE: functionDate(selectedUser.LTDateApply),
          EDUCATION_QUALIFICATION: selectedUser.education,
          STATE: selectedUser.stateAssociation,
          "Group Name": selectedUser.groupName_Optional,
          "Registered From": selectedUser.Registeredfrom,
          "Charter No": selectedUser.charterNo || "N/A",
          "Warrant No": selectedUser.warrantNo,
          "Warrant Date": new Date(selectedUser.warrantDate).toDateString(),
          "District Name": selectedUser.NameOfDistrict,
          Place: selectedUser.Place,
          "HWB Parchment No": selectedUser.HwbparchmentNo,
          "HWB Parchment Date": new Date(
            selectedUser.HwbparchmentDate
          ).toDateString(),
          "Current Rank": selectedUser.currentRank,
          REGION: "NA",
          TRG_IN_OTHER_SECTION: "NA",
          BSG_QUALIFICATION_WITH_SECTION: "NA",
          AADHAR_NO: "NA",
          MOBILE: "NA",
        },
      ];

      // Create a worksheet from the formatted data
      const ws = XLSX.utils.json_to_sheet(userData);

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "User Data");

      // Export the file as an Excel (.xlsx)
      XLSX.writeFile(wb, `User_${selectedUser.Bsguid || "Unknown"}_Data.xlsx`);
    } catch (error) {
      console.error("Error generating Excel file:", error);
      alert("An error occurred while generating the Excel file.");
    }
  };

  const handleScorecardChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setScorecard(file);
    }
  };

  return (
    <>
      <div className="grid grid-cols-3">
        <label
          className="mb-10 font-semibold text-white bg-purple-600 w-24 p-1 rounded-sm text-xs cursor-pointer"
          onClick={downloadExcel}
        >
          Download Excel for Scorecard
        </label>
        <h2 className="text-center text-xl uppercase font-bold  mb-14">
          ALT Application Data
        </h2>
      </div>

      <div className="lt-application-data text-xs">
        <div className="flex flex-col gap-2">
          {data.map((item, index) => (
            <div
              key={index}
              className="flex flex-row items-center gap-2 text-xs"
            >
              <div>
                {index + 1}. {item.candidateName} ({item.Bsguid})
              </div>

           

              <div className="status ">
                <strong>State Status:</strong>{" "}
                <span className="text-red-500 font-semibold">
                  {status[item.userId] ? "AcceptedByAdmin" : "Pending"}
                </span>
              </div>

              <div className="status">
                <strong>Ntc Status:</strong>{" "}
                <span className="text-red-500 font-semibold">
                  {ntcStatus[item.userId] || "Pending"}
                </span>
              </div>


              <div className="status-update">
                <label className="block mb-0.5 font-semibold text-xs">
                  UpdateStatus(NTC)
                </label>
                <Select
                  className="text-xs "
                  options={[
                    { value: "AcceptedByNTC", label: "Accept" },
                    { value: "RejectedByNTC", label: "Reject" },
                  ]}
                  onChange={(selectedOption) =>
                    updateApplicationStatus(item.userId, selectedOption.value)
                  }
                  placeholder="Select"
                />
              </div>
              <div className="ml-8">
                <label className="block mb-0.5 font-semibold text-xs">
                  Upload Scorecard
                </label>
                <input
                  type="file"
                  onChange={handleScorecardChange}
                  className="mb-1 h-6 text-xs"
                />
                <button
                  onClick={() => handleSubmitScorecard(item.Bsguid)}
                  className="bg-blue-500 text-white p-1 rounded-sm text-xs w-24"
                >
                  Submit
                </button>
              </div>

              <div className="button-group">
                <button
                  onClick={() => {
                    setSelectedUser(item);
                    setModalIsOpen(true);
                  }}
                  className="btn bg-green-400 rounded-sm p-1 text-xs font-semibold w-24"
                >
                  Show Data
                </button>
              </div>
              { role === "superadmin" || assignment === "nhq_upload" ? (
              <div className="button-group">
                <button
                  onClick={() => {
                    setSelectedUser(item);
                    downloadLtExcelUpload(item); // Pass item as argument
                  }}
                  className="btn bg-green-400 rounded-sm p-1 text-xs font-semibold w-24"
                >
                  Download Excel
                </button>
              </div>):null}


            </div>
          ))}

          {/* Modal */}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={{ content: { width: "40%", margin: "auto" } }}
          >
            {selectedUser && (
              <div className="data-display text-xs">
                <h2 className="font-semibold">User Details</h2>
                <img
                  src={selectedUser.candidatePhoto}
                  alt="User"
                  className="w-20"
                />
                <div>
                  <strong>Wing:</strong> {selectedUser.wing}
                </div>
                <div>
                  <strong>State Association:</strong>{" "}
                  {selectedUser.stateAssociation}
                </div>
                <div>
                  <strong>Candidate Name:</strong> {selectedUser.candidateName}
                </div>
                <div>
                  <strong>Email:</strong> {selectedUser.email}
                </div>
                <div>
                  <strong>BSG UID:</strong> {selectedUser.Bsguid}
                </div>
                <div>
                  <strong>Date of Birth:</strong>{" "}
                  {new Date(selectedUser.dateOfBirth).toDateString()}
                </div>
                <div>
                  <strong>Age:</strong> {selectedUser.age}
                </div>
                <div>
                  <strong>Education:</strong> {selectedUser.education}
                </div>
                <div>
                  <strong>Pincode:</strong> {selectedUser.pincode}
                </div>
                <div>
                  <strong>Group Name:</strong> {selectedUser.groupName_Optional}
                </div>
                <div>
                  <strong>Registered From:</strong>{" "}
                  {selectedUser.Registeredfrom}
                </div>
                <div>
                  <strong>Charter No:</strong> {selectedUser.charterNo || "N/A"}
                </div>
                <div>
                  <strong>Warrant No:</strong> {selectedUser.warrantNo}
                </div>
                <div>
                  <strong>Warrant Date:</strong>{" "}
                  {new Date(selectedUser.warrantDate).toDateString()}
                </div>
                <div>
                  <strong>District Name:</strong> {selectedUser.NameOfDistrict}
                </div>
                <div>
                  <strong>Place:</strong> {selectedUser.Place}
                </div>
                <div>
                  <strong>HWB Parchment No:</strong>{" "}
                  {selectedUser.HwbparchmentNo}
                </div>
                <div>
                  <strong>HWB Parchment Date:</strong>{" "}
                  {new Date(selectedUser.HwbparchmentDate).toDateString()}
                </div>
                <div>
                  <strong>Current Rank:</strong> {selectedUser.currentRank}
                </div>
              </div>
            )}
            <button
              onClick={() => setModalIsOpen(false)}
              className="btn bg-red-500 text-white p-1 rounded-sm text-xs w-20"
            >
              Close
            </button>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ALtApplicationData;
