import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../constant/constant";

const CreateAdmin = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("admin");
  const [assignments, setAssignments] = useState([]); // Support multiple assignments for SuperAdmin

  const [admin, setAdmin] = useState([]);
  const token = localStorage.getItem("adminToken");
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const handleCreateAdmin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/register`,
        { name, email, password, role, assignments }, // Include assignments in payload
        { headers: { "Content-Type": "application/json" } }
      );
      console.log(response, "response");
      alert(
        `${role.charAt(0).toUpperCase() + role.slice(1)} created successfully`
      );
      setName("");
      setEmail("");
      setPassword("");
      setRole("admin"); // Reset to default role
      setAssignments([]); // Reset assignments
    } catch (err) {
      alert("Error creating admin");
    }
  };

  const getAdmin = async () => {
    const response = await axios.get(`${BASE_URL}/api/v1/getAdmin`,axiosConfig);
    console.log(response.data, "response");
    setAdmin(response.data);
  };

  useEffect(() => {
    getAdmin();
  }, []);

  const handleToggleDisable = async (id, currentStatus) => {
    
    try {
      
      await axios.post(`${BASE_URL}/api/admin/updateAdminStatus`, {
        id,
        isDisabled: !currentStatus,
      });

      setAdmin((prevAdmins) =>
        prevAdmins.map((adminUser) =>
          adminUser._id === id
            ? { ...adminUser, isDisabled: !currentStatus }
            : adminUser
        )
      );
      alert("Status updated successfully");
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Error updating status");
    }
  };

  const dropdownOptions = [
    { label: "NTC Upload", value: "ntc_upload" },
    { label: "NHQ Checker", value: "nhq_checker" },
    { label: "CNC Approval", value: "cnc_approval" },
  ];

  const handleAssignmentChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setAssignments((prev) => [...prev, value]);
    } else {
      setAssignments((prev) => prev.filter((assignment) => assignment !== value));
    }
  };

  const availableOptions =
    role === "superadmin"  ? dropdownOptions : dropdownOptions.slice(0, 3); // SuperAdmin gets all; Admin gets limited options

  return (
    <>
      <form
        onSubmit={handleCreateAdmin}
        className="space-y-4 p-4 border border-[#4dc9ff]  bg-white rounded shadow-md max-w-md mx-auto"
      >
        <h1 className="text-xl font-bold text-center">
          Create Admin or SuperAdmin
        </h1>

        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-3 py-2 border border-[#4dc9ff] rounded"
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 border border-[#4dc9ff] rounded"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-3 py-2 border border-[#4dc9ff] rounded"
          required
        />

        <select
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className="w-full px-3 py-2 border border-[#4dc9ff] rounded"
          required
        >
          <option value="admin">Admin</option>
          <option value="superadmin">SuperAdmin</option>
        </select>

        {role === "superadmin" ? (
          <div>
            <h3 className="font-medium mb-2">Work Assignment</h3>
            {dropdownOptions.map((option) => (
              <label key={option.value} className="block mb-1">
                <input
                  type="checkbox"
                  value={option.value}
                  
                  onChange={handleAssignmentChange}
                  className="mr-2"
                />
                {option.label}
              </label>
            ))}
          </div>
        ) : (
          <select
            value={assignments[0] || ""}
            onChange={(e) => setAssignments([e.target.value])}
            className="w-full px-3 py-1 border border-[#4dc9ff] rounded"
            required
          >
            <option value="">Select Assignment</option>
            {availableOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
    </select>
        )}

        <button
          type="submit"
          className="border-[#4dc9ff]   bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white  font-medium shadow-md transform active:scale-95 transition-all duration-300 ease-in-out  px-4 py-2 rounded hover:bg-blue-600 w-full"
        >
          Create {role.charAt(0).toUpperCase() + role.slice(1)}
        </button>
      </form>

      <div className="overflow-x-auto mt-10">
  <table
    className="w-3/4 table-auto border-collapse border border-[#4dc9ff]  shadow-lg mx-auto"
  >
    <thead>
      <tr className="border border-[#4dc9ff]   bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white">
        <th className="px-3 py-2 text-xs font-semibold border border-gray-200">#</th>
        <th className="px-3 py-2 text-xs font-semibold border border-gray-200">Name</th>
        <th className="px-3 py-2 text-xs font-semibold border border-gray-200">Email</th>
        <th className="px-3 py-2 text-xs font-semibold border border-gray-200">Role</th>
        <th className="px-3 py-2 text-xs font-semibold border border-gray-200">Assignments</th>
        <th className="px-3 py-2 text-xs font-semibold border border-gray-200">Status</th>
        <th className="px-3 py-2 text-xs font-semibold border border-gray-200">Actions</th>
      </tr>
    </thead>
    <tbody>
      {admin.map((adminUser, index) => (
        <tr
          key={index}    
          className={`text-center ${
            index % 2 === 0 ? "bg-gray-100" : "bg-white"
          } hover:bg-blue-100`}
        >
          <td className="px-3 py-2 text-xs font-semibold border border-gray-200 ">{index + 1}</td>
          <td className="px-3 py-2 text-xs font-semibold border border-gray-200">
            {adminUser.name}
          </td>
          <td className="px-3 py-2 text-xs font-semibold border border-gray-200">
            {adminUser.email}
          </td>
          <td className="px-3 py-2 text-xs font-semibold border border-gray-200">
            {adminUser.role}
          </td>
          <td className="px-3 py-2 text-xs font-semibold border border-gray-200">
            {(adminUser.assignments || []).join(", ") || "N/A"}
          </td>
          <td className="px-3 py-2 text-xs font-semibold border border-gray-200">
            {adminUser.isDisabled ? (
              <span className="text-red-500 font-semibold">Disabled</span>
            ) : (
              <span className="text-green-500 font-semibold">Active</span>
            )}
          </td>
          <td className="px-3 py-2 text-xs font-semibold border border-gray-200">
            <button
              onClick={() =>
                handleToggleDisable(adminUser._id, adminUser.isDisabled)
              }
              className={`px-4 py-2 text-white rounded ${
                adminUser.isDisabled ? "bg-green-500" : "bg-red-500"
              } hover:opacity-80`}
            >
              {adminUser.isDisabled ? "Enable" : "Disable"}
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

    </>
  );
};

export default CreateAdmin;
