import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../constant/constant";
import axios from "axios";
import * as XLSX from "xlsx";

const KytData = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [personal, setPersonal] = useState(null);
  const [lt, setLt] = useState(null);
  const [alt, setAlt] = useState(null);
  const [advance, setAdvance] = useState(null);
  const [basic, setBasic] = useState(null);
  const [hwb, setHwb] = useState(null);
  const [activeData, setActiveData] = useState(null);
  const [userStatus, setUserStatus] = useState({});
  const [openBox, setOpenBox] = useState(null);
  const [dataAvailability, setDataAvailability] = useState({});

  const type = [
    { label: "Personal Details", key: "personalDetails" },
    { label: "LT Details", key: "ltDetails" },
    { label: "ALT Details", key: "aLtDetails" },
    { label: "Basic Details", key: "basicDetails" },
    { label: "Advance Details", key: "advanceDetails" },
    { label: "HWB Details", key: "hwbDetails" },
  ];

  async function getDetail(type, userId, userPId) {
    console.log(type, userId, userPId, "userId");
    try {
      if (!userId) {
        throw new Error("userId is required");
      }
      let response;
      let dataExists = false; // Flag to check if data exists
      switch (type) {
        case "personalDetails":
          response = await axios.get(
            `${BASE_URL}/api/v2/allKytpersonalddetails/${userPId}`
          );
          setPersonal(response.data.data);
          dataExists = response.data.data !== null; // Check if data exists
          break;
        case "ltDetails":
          response = await axios.get(`${BASE_URL}/api/v2/allKytLt/${userId}`);
          setLt(response.data.data);
          dataExists = response.data.data !== null; // Check if data exists
          break;
        case "aLtDetails":
          response = await axios.get(`${BASE_URL}/api/v2/allKytALt/${userId}`);
          setAlt(response.data.data);
          dataExists = response.data.data !== null; // Check if data exists
          break;
        case "basicDetails":
          response = await axios.get(
            `${BASE_URL}/api/v2/allKytBasic/${userId}`
          );
          setBasic(response.data.data);
          dataExists = response.data.data !== null; // Check if data exists
          break;
        case "advanceDetails":
          response = await axios.get(
            `${BASE_URL}/api/v2/allKytAdvance/${userId}`
          );
          setAdvance(response.data.data);
          dataExists = response.data.data !== null; // Check if data exists
          break;
        case "hwbDetails":
          response = await axios.get(`${BASE_URL}/api/v2/allKytHwb/${userId}`);
          setHwb(response.data.data);
          dataExists = response.data.data !== null; // Check if data exists
          break;
        default:
          throw new Error("Invalid type provided");
      }
      setActiveData(type);

      // Update data availability state
      setDataAvailability((prev) => ({
        ...prev,
        [userId]: {
          ...prev[userId],
          [type]: dataExists,
        },
      }));
    } catch (error) {
      console.error("Error fetching details:", error.message);
      setError(error.message);
    }
  }

  const functionDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/v2/allKytpersonalDetails`
        );
        const feedbackList = response.data.data;

        const statusMap = feedbackList.reduce((acc, feedback) => {
          acc[feedback._id] = feedback.status || "pending"; // Default status
          return acc;
        }, {});

        // Initialize dataAvailability
        const initialDataAvailability = {};
        feedbackList.forEach((user) => {
          initialDataAvailability[user.userId] = {
            personalDetails: false,
            ltDetails: false,
            aLtDetails: false,
            basicDetails: false,
            advanceDetails: false,
            hwbDetails: false,
          };
        });

        setUserStatus(statusMap);
        setUserList(feedbackList);
        setDataAvailability(initialDataAvailability); // Set initial data availability
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handleValid = async (userId) => {
    const confirmed = window.confirm(
      "Are you sure you want to mark this as valid?"
    );
    if (!confirmed) {
      return;
    }

    try {
      const response = await axios.put(`${BASE_URL}/api/v2/personal`, {
        userId: userId,
        status: "accepted",
      });
      console.log(response.data.success, "response");
      if (response.data.success) {
        alert("Data updated successfully");
        const updatedStatus = { ...userStatus, [userId]: "accepted" };
        console.log(updatedStatus, "updateStaus");
        setUserStatus(updatedStatus);
      }
    } catch (error) {
      console.error(error);
      alert("Error updating data");
    }
  };

  const handleInValid = async (userId) => {
    const confirmed = window.confirm(
      "Are you sure you want to mark this as Invalid?"
    );
    if (!confirmed) {
      return;
    }

    try {
      const response = await axios.put(`${BASE_URL}/api/v2/personalInValid`, {
        userId: userId,
        status: "rejected",
      });
      if (response.data.success) {
        alert("Data updated successfully");
        const updatedStatus = { ...userStatus, [userId]: "rejected" };
        setUserStatus(updatedStatus);
      }
    } catch (error) {
      console.error(error);
      alert("Error updating data");
    }
  };

  const exportAllToExcel = async () => {
    const allUserData = [];

    const userData = {
      PersonalDetails: [],
      LtDetails: [],
      AltDetails: [],
      BasicDetails: [],
      AdvanceDetails: [],
      HwbDetails: [],
    };

    try {
      // Fetch Personal Details
      const personalResponse = await axios.get(
        `${BASE_URL}/api/v2/allpersonal`
      );
      userData.PersonalDetails = personalResponse.data.data || [];

      // Fetch LT Details
      const ltResponse = await axios.get(`${BASE_URL}/api/v2/alllt`);
      userData.LtDetails = ltResponse.data.data || []; // Ensure it's an array
      console.log(ltResponse.data.data, "ltdata");
      // Fetch ALT Details
      const altResponse = await axios.get(`${BASE_URL}/api/v2/allalt`);
      userData.AltDetails = altResponse.data.data || []; // Ensure it's an array

      // Fetch Basic Details
      const basicResponse = await axios.get(`${BASE_URL}/api/v2/allbasic`);
      userData.BasicDetails = basicResponse.data.data || []; // Ensure it's an array

      // Fetch Advance Details
      const advanceResponse = await axios.get(`${BASE_URL}/api/v2/alladvance`);
      userData.AdvanceDetails = advanceResponse.data.data || []; // Ensure it's an array

      // Fetch HWB Details
      const hwbResponse = await axios.get(`${BASE_URL}/api/v2/allhwb`);
      userData.HwbDetails = hwbResponse.data.data || []; // Ensure it's an array

      // Push user data to the allUser  Data array
      allUserData.push(userData);
    } catch (error) {
      console.error("Error fetching user data:", error);
      alert("Error fetching user data for export.");
      return;
    }

    // Prepare data for Excel export
    const excelData = [];

    allUserData.forEach((userData, index) => {
      if (userData.PersonalDetails.length > 0) {
        userData.PersonalDetails.forEach((PersonalDetail, ltIndex) => {
          excelData.push({
            Section: `User     ${index + 1} - Personal Details ${ltIndex + 1}`,
            ...PersonalDetail,
          });
        });
      }
      if (userData.LtDetails.length > 0) {
        userData.LtDetails.forEach((ltDetail, ltIndex) => {
          excelData.push({
            Section: `User     ${index + 1} - LT Details ${ltIndex + 1}`,
            ...ltDetail,
          });
        });
      }
      if (userData.AltDetails.length > 0) {
        userData.AltDetails.forEach((altDetail, altIndex) => {
          excelData.push({
            Section: `User     ${index + 1} - ALT Details ${altIndex + 1}`,
            ...altDetail,
          });
        });
      }
      if (userData.BasicDetails.length > 0) {
        userData.BasicDetails.forEach((basicDetail, basicIndex) => {
          excelData.push({
            Section: `User     ${index + 1} - Basic Details ${basicIndex + 1}`,
            ...basicDetail,
          });
        });
      }
      if (userData.AdvanceDetails.length > 0) {
        userData.AdvanceDetails.forEach((advanceDetail, advanceIndex) => {
          excelData.push({
            Section: `User     ${index + 1} - Advance Details ${
              advanceIndex + 1
            }`,
            ...advanceDetail,
          });
        });
      }
      if (userData.HwbDetails.length > 0) {
        userData.HwbDetails.forEach((hwbDetail, hwbIndex) => {
          excelData.push({
            Section: `User     ${index + 1} - HWB Details ${hwbIndex + 1}`,
            ...hwbDetail,
          });
        });
      }
    });

    // Check if there's any data to export
    if (excelData.length === 0) {
      console.warn("No data available for export.");
      return;
    }

    // Create Excel file
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "All User Data");

    // Export the Excel file
    XLSX.writeFile(workbook, `All_Users_Data.xlsx`);
  };

  const exportToExcel = async (user, specificUserId) => {
    console.log("User    :", user);
    console.log("Specific User ID:", specificUserId);

    const userData = {
      PersonalDetails: {},
      LtDetails: [],
      AltDetails: [],
      BasicDetails: [],
      AdvanceDetails: [],
      HwbDetails: [],
    };

    try {
      // Fetch Personal Details
      const personalResponse = await axios.get(
        `${BASE_URL}/api/v2/allKytpersonalddetails/${user._id}`
      );
      userData.PersonalDetails = personalResponse.data.data;

      // Fetch LT Details
      const ltResponse = await axios.get(
        `${BASE_URL}/api/v2/allKytLt/${specificUserId}`
      );
      userData.LtDetails = ltResponse.data.data || []; // Ensure it's an array

      // Fetch ALT Details
      const altResponse = await axios.get(
        `${BASE_URL}/api/v2/allKytALt/${specificUserId}`
      );
      userData.AltDetails = altResponse.data.data || []; // Ensure it's an array

      // Fetch Basic Details
      const basicResponse = await axios.get(
        `${BASE_URL}/api/v2/allKytBasic/${specificUserId}`
      );
      userData.BasicDetails = basicResponse.data.data || []; // Ensure it's an array

      // Fetch Advance Details
      const advanceResponse = await axios.get(
        `${BASE_URL}/api/v2/allKytAdvance/${specificUserId}`
      );
      userData.AdvanceDetails = advanceResponse.data.data || []; // Ensure it's an array

      // Fetch HWB Details
      const hwbResponse = await axios.get(
        `${BASE_URL}/api/v2/allKytHwb/${specificUserId}`
      );
      userData.HwbDetails = hwbResponse.data.data || []; // Ensure it's an array
    } catch (error) {
      console.error("Error fetching user data:", error);
      alert("Error fetching user data for export.");
      return;
    }

    const excelData = [];

    if (Object.keys(userData.PersonalDetails).length > 0) {
      excelData.push({
        Section: "Personal Details",
        ...userData.PersonalDetails,
      });
    }
    if (Object.keys(userData.LtDetails).length > 0) {
      excelData.push({
        Section: "Lt Details",
        ...userData.LtDetails,
      });
    }

    if (Object.keys(userData.AltDetails).length > 0) {
      excelData.push({
        Section: "Alt Details",
        ...userData.AltDetails,
      });
    }
    if (Object.keys(userData.BasicDetails).length > 0) {
      excelData.push({
        Section: "Basic Details",
        ...userData.BasicDetails,
      });
    }
    if (Object.keys(userData.AdvanceDetails).length > 0) {
      excelData.push({
        Section: "Advance Details",
        ...userData.AdvanceDetails,
      });
    }
    if (Object.keys(userData.HwbDetails).length > 0) {
      excelData.push({
        Section: "Hwb Details",
        ...userData.HwbDetails,
      });
    }

    // Check if there's any data to export
    if (excelData.length === 0) {
      console.warn("No data available for export.");
      return;
    }

    // Create Excel file
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "User    Data");

    // Export the Excel file
    XLSX.writeFile(workbook, `${user.name}_data.xlsx`);
  };

  return (
    <>
      {loading ? (
        <div>Loading</div>
      ) : (
        <>
          <div className="flex justify-between">
            <h1 className="text-3xl font-extrabold text-center mb-10 text-gray-900 uppercase tracking-widest hover:text-blue-500 transition-all duration-300">
              KYT Data Overview
            </h1>

            <div
              className="font-semibold rounded-md h-7 px-4 bg-[#1D56A5] text-white cursor-pointer"
              onClick={exportAllToExcel}
            >
              All Export To Excel
            </div>
          </div>

          <div className="">
            {userList.map((user, index) => (
              <div
                key={index}
                className="user-section bg-white shadow-lg rounded-2xl p-4 hover:shadow-2xl transition-shadow duration-300 mt-5"
                onClick={() => {
                  setOpenBox(index === openBox ? null : index);
                }}
              >
                <div className="flex flex-row lg:flex-row items-start lg:items-center justify-between">
                  <div className="flex flex-row space-y-1">
                    <div
                      key={index}
                      className="text-xs w-10 font-semibold text-gray-800"
                    >
                      {index + 1}
                    </div>
                    <div className="text-xs w-20 font-semibold text-gray-800">
                      {user.name}
                    </div>
                    <div className="text-xs w-20 text-gray-500">
                      {user.bsgUid}
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 p-2">
                    {type.map((typeItem, typeIndex) => (
                      <div
                        key={`${index}-${typeIndex}`}
                        className="type-item bg-blue-100 text-blue-600 px-2 rounded-full text-xs font-semibold cursor-pointer hover:bg-blue-200 transition-colors"
                        onClick={() =>
                          getDetail(typeItem.key, user.userId, user._id)
                        }
                      >
                        {typeItem.label}
                        {dataAvailability[user.userId] &&
                        dataAvailability[user.userId][typeItem.key] ? (
                          <span className="text-green-500 ml-2">✔️</span> // Tick icon
                        ) : (
                          <span className="text-red-500 ml-2">❌</span> // Cross icon
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 flex space-x-2">
                    <button
                      className={`px-4 py-2 rounded text-white ${
                        userStatus[user._id] === "accepted"
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-green-600 hover:bg-green-700"
                      }`}
                      onClick={() => handleValid(user._id)}
                      disabled={userStatus[user._id] === "accepted"}
                    >
                      {userStatus[user._id] === "accepted"
                        ? "Accepted By Admin"
                        : "Accept"}
                    </button>

                    <button
                      className={`px-4 py-2 rounded text-white ${
                        userStatus[user._id] === "rejected"
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-red-600 hover:bg-red-700"
                      }`}
                      onClick={() => handleInValid(user._id)}
                      disabled={userStatus[user._id] === "rejected"}
                    >
                      {userStatus[user._id] === "rejected"
                        ? "Rejected By Admin"
                        : "Reject"}
                    </button>
                    <div
                      className="bg-blue-500 text-white rounded-sm py-3 px-1 text-xs font-medium cursor-pointer hover:bg-blue-600 transition-all duration-300 transform hover:scale-105"
                      onClick={() => exportToExcel(user, user.userId)}
                    >
                      Export To Excel
                    </div>
                  </div>
                </div>

                {openBox === index && (
                  <div className="mt-6">
                    {activeData === "personalDetails" && personal && (
                      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 p-4">
                        {Object.entries(personal).map(
                          ([key, value]) =>
                            ![
                              "_id",
                              "userId",
                              "createdAt",
                              "updatedAt",
                              "status",
                              "__v",
                            ].includes(key) &&
                            value && (
                              <div
                                key={key}
                                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                              >
                                <strong className="text-sm font-semibold capitalize text-gray-700">
                                  {key.replace(/([A-Z])/g, " $1")}:
                                </strong>{" "}
                                {key === "highestqualification" ||
                                key === "uploadPhoto" ||
                                key === "uploadWarrant" ? (
                                  <a
                                    href={value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 hover:text-blue-700"
                                  >
                                    View {key}
                                  </a>
                                ) : (
                                  <span className="text-gray-600">{value}</span>
                                )}
                              </div>
                            )
                        )}
                      </div>
                    )}

                    {activeData === "ltDetails" && lt && (
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-4">
                        {Object.entries(lt.courseDetails || {}).map(
                          ([key, value]) =>
                            ![
                              "_id",
                              "userId",
                              "createdAt",
                              "updatedAt",
                              "status",
                              "__v",
                            ].includes(key) &&
                            value && (
                              <div
                                key={key}
                                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                              >
                                <strong className="text-sm font-semibold capitalize text-gray-700">
                                  {key.replace(/([A-Z])/g, " $1")}:
                                </strong>{" "}
                                {!isNaN(Date.parse(value)) ? (
                                  functionDate(value)
                                ) : (
                                  <span className="text-gray-600">{value}</span>
                                )}
                              </div>
                            )
                        )}
                      </div>
                    )}

                    {activeData === "aLtDetails" && alt && (
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-4">
                        {Object.entries(alt.courseDetails || {}).map(
                          ([key, value]) =>
                            ![
                              "_id",
                              "userId",
                              "createdAt",
                              "updatedAt",
                              "status",
                              "__v",
                            ].includes(key) &&
                            value && (
                              <div
                                key={key}
                                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                              >
                                <strong className="text-sm font-semibold capitalize text-gray-700">
                                  {key.replace(/([A-Z])/g, " $1")}:
                                </strong>{" "}
                                {!isNaN(Date.parse(value)) ? (
                                  functionDate(value)
                                ) : (
                                  <span className="text-gray-600">{value}</span>
                                )}
                              </div>
                            )
                        )}
                      </div>
                    )}

                    {activeData === "basicDetails" && basic && (
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-4">
                        {Object.entries(basic || {}).map(
                          ([key, value]) =>
                            ![
                              "_id",
                              "userId",
                              "createdAt",
                              "updatedAt",
                              "status",
                              "__v",
                              "isSubmitted",
                            ].includes(key) &&
                            value && (
                              <div
                                key={key}
                                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                              >
                                <strong className="text-sm font-semibold capitalize text-gray-700">
                                  {key.replace(/([A-Z])/g, " $1")}:
                                </strong>{" "}
                                {!isNaN(Date.parse(value)) ? (
                                  functionDate(value)
                                ) : (
                                  <span className="text-gray-600">{value}</span>
                                )}
                              </div>
                            )
                        )}
                      </div>
                    )}

                    {activeData === "advanceDetails" && advance && (
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-4">
                        {Object.entries(advance || {}).map(
                          ([key, value]) =>
                            ![
                              "_id",
                              "userId",
                              "createdAt",
                              "updatedAt",
                              "status",
                              "__v",
                              "isSubmitted",
                            ].includes(key) &&
                            value && (
                              <div
                                key={key}
                                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                              >
                                <strong className="text-sm font-semibold capitalize text-gray-700">
                                  {key.replace(/([A-Z])/g, " $1")}:
                                </strong>{" "}
                                {!isNaN(Date.parse(value)) ? (
                                  functionDate(value)
                                ) : (
                                  <span className="text-gray-600">{value}</span>
                                )}
                              </div>
                            )
                        )}
                      </div>
                    )}

                    {activeData === "hwbDetails" && hwb && (
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-4">
                        {Object.entries(hwb || {}).map(
                          ([key, value]) =>
                            ![
                              "_id",
                              "userId",
                              "createdAt",
                              "updatedAt",
                              "status",
                              "__v",
                              "uploadCertificate",
                              "uploadparchment",
                              "isSubmitted",
                            ].includes(key) &&
                            value && (
                              <div
                                key={key}
                                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                              >
                                <strong className="text-sm font-semibold capitalize text-gray-700">
                                  {key.replace(/([A-Z])/g, " $1")}:
                                </strong>{" "}
                                {!isNaN(Date.parse(value)) ? (
                                  functionDate(value)
                                ) : (
                                  <span className="text-gray-600">{value}</span>
                                )}
                              </div>
                            )
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default KytData;
