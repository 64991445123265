import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { BASE_URL } from "../../constant/constant";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";

const AddBSGState = () => {
  const [revenuestate, setRevenueState] = useState("");
  const [states, setStates] = useState([]);

  const handleSubmit = async () => {
    if (!revenuestate.trim()) {
      Swal.fire("Error!", "Revenue state cannot be empty!", "error");
      return;
    }

    const data = { revenuestate };
    try {
      const userId = localStorage.getItem("id");
      console.log(userId, "userId");

      const response = await axios.post(
        `${BASE_URL}/api/v1/addrevenuestate/${userId}`,
        data
      );

      console.log(response.data);
      setRevenueState("");

      Swal.fire("Success!", "Revenue State Added Successfully!", "success");
      getState();
    } catch (error) {
      console.log(error, "error");
      Swal.fire("Error!", "Failed to add revenue state!", "error");
    }
  };

  useEffect(() => {
    getState();
  }, []);

  const getState = async () => {
    try {
      const userId = localStorage.getItem("id");
      console.log(userId, "userId");

      const response = await axios.get(
        `${BASE_URL}/api/v1/getrevenuestate/${userId}`
      );

      console.log(response, "response");
      setStates(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          console.log(id, "id");
          const response = await fetch(
            `${BASE_URL}/api/v1/deleterevenuestate/${id}`,
            {
              method: "DELETE",
              headers: { "Content-Type": "application/json" },
            }
          );

          if (response.ok) {
            Swal.fire("Deleted!", "Revenue state has been deleted.", "success");
            getState();
          } else {
            Swal.fire("Error!", "Failed to delete revenue state!", "error");
          }
        } catch (error) {
          console.error("Error deleting category:", error);
          Swal.fire("Error!", "Something went wrong!", "error");
        }
      }
    });
  };

  return (
    <>
      <div className="pt-1 flex items-center justify-center ">
        <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-sm border border-[#4dc9ff]">
          <h1 className="text-2xl font-bold mb-4 text-center text-gray-800">
            ADD REVENUE STATE
          </h1>
          <ToastContainer />
          <input
            type="text"
            placeholder="Enter the Revenue State"
            value={revenuestate}
            onChange={(e) => setRevenueState(e.target.value)}
            className="border border-[#4dc9ff] rounded-md w-full p-2 mb-4"
          />
          <button
            onClick={handleSubmit}
            className="w-full border border-[#4dc9ff] bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] transform active:scale-95 ease-in-out disabled:opacity-50 shadow-lg text-white py-2 px-4 rounded-md transition duration-200"
          >
            Submit
          </button>
        </div>
      </div>

      <div className="font-bold text-2xl text-black mb-6 uppercase">
        All Revenue States
      </div>
      <div className="space-y-4">
        {states.map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center border border-[#4dc9ff] p-2 bg-white rounded-lg shadow-md transition"
          >
            <div className="text-md text-gray-700">{item.revenuestate}</div>
            <button
              data-tooltip-id="deleteTooltip"
              data-tooltip-content="Delete Revenue State"
              className="text-red-500 hover:text-red-700 transition"
              onClick={() => handleDelete(item._id)}
            >
              <MdDelete style={{ fontSize: 24 }} />
            </button>
            <Tooltip id="deleteTooltip" className="p-2 text-xs" />
          </div>
        ))}
      </div>
    </>
  );
};

export default AddBSGState;