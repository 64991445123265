import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "chart.js/auto";
import { BASE_URL } from "../constant/constant";
import CountUp from "react-countup";
import { Tooltip } from "react-tooltip";

const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [altCount, setAltCount] = useState(0);
  const [ltCount, setLtCount] = useState(0);
  const [loaderPercentage, setLoaderPercentage] = useState(0);
  const [loaderColor, setLoaderColor] = useState("bg-gray-200");
  const [role, setUserRole] = useState("");
  const [assignment, setUserAssignment] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [usersData, setUsers] = useState([]);
  const [usersData1, setUsers1] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [currentViewState, setCurrentViewState] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterType, setFilterType] = useState(null);
  const [currentViewState1, setCurrentViewState1] = useState(null);
  const [filteredUsers1, setFilteredUsers1] = useState([]);
  const [filterType1, setFilterType1] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUser1, setSelectedUser1] = useState(null);
  const [totals, setTotals] = useState({
    valid: 0,
    invalid: 0,
    validKYTScout: 0,
    validKYTGuide: 0,
    validKYTCub: 0,
    validKYTBulbul: 0,
    validKYTRover: 0,
    validKYTRanger: 0,
    HC_VALID_AND_KYT_DONE: 0,
    HC_valid_But_KYT_Not_Done: 0,
    HC_INVALID_BUT_KYT_DONE: 0,
  });
  const [totals1, setTotals1] = useState({
    valid: 0,
    invalid: 0,
    validKYTScout: 0,
    validKYTGuide: 0,
    validKYTCub: 0,
    validKYTBulbul: 0,
    validKYTRover: 0,
    validKYTRanger: 0,
    HC_VALID_AND_KYT_DONE: 0,
    HC_valid_But_KYT_Not_Done: 0,
    HC_INVALID_BUT_KYT_DONE: 0,
  });
  const navigate = useNavigate();

  const token = localStorage.getItem("adminToken");


  function blockBackButton() {
    if (token) {

      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, null, window.location.href); // Keep the user on the current page
      };

      // Listen for beforeunload event to show alert when the user tries to leave the page
      window.addEventListener("beforeunload", (event) => {
        event.preventDefault(); // Standard for most browsers
        event.returnValue = ""; // Some browsers require this
      });
    }
  }

  blockBackButton();

  // Function to show an exit alert when trying to navigate away from the dashboard
  function confirmExit() {
    const isConfirmed = window.confirm("Are you sure you want to exit?");
    if (isConfirmed) {
      // Remove token and navigate to login page
      localStorage.removeItem("adminToken");
      window.location.href = "/login"; // Redirect to login page
    }
  }
  document.querySelector("#exitButton")?.addEventListener("click", confirmExit); // Example of adding event listener to a custom exit button

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,

      "Content-Type": "application/json",
    },
  };

  // useEffect(() => {
  //   const storedData = JSON.parse(localStorage.getItem("dashboardData"));

  //   if (storedData) {
  //     setUserCount(storedData.userCount);
  //     setAltCount(storedData.altCount);
  //     setLtCount(storedData.ltCount);
  //     setInitialLoading(false);
  //   } else {
  //     const totalAPIs = 3;
  //     let completedAPIs = 0;

  //     const updateLoader = () => {
  //       completedAPIs++;
  //       setLoaderPercentage((completedAPIs / totalAPIs) * 100);
  //       if (completedAPIs === 1) setLoaderColor("bg-yellow-500");
  //       if (completedAPIs === 2) setLoaderColor("bg-yellow-500");
  //       if (completedAPIs === 3) setLoaderColor("bg-yellow-500");
  //     };

  //     const tempData = { userCount: 0, altCount: 0, ltCount: 0 };

  //     Promise.all([
  //       axios
  //         .get(`${BASE_URL}/api/v1/hwbuser`, axiosConfig)
  //         .then((response) => {
  //           tempData.userCount = response.data.length;
  //           setUserCount(response.data.length);
  //           updateLoader();
  //         })
  //         .catch(() => {
  //           toast.error("Error fetching user data.");
  //         }),
  //       axios
  //         .get(`${BASE_URL}/api/v1/altuser`, axiosConfig)
  //         .then((response) => {
  //           tempData.altCount = response.data.length;
  //           setAltCount(response.data.length);
  //           updateLoader();
  //         })
  //         .catch(() => {
  //           toast.error("Error fetching ALT user data.");
  //         }),
  //       axios
  //         .get(`${BASE_URL}/api/v1/ltuser`, axiosConfig)
  //         .then((response) => {
  //           tempData.ltCount = response.data.length;
  //           setLtCount(response.data.length);
  //           updateLoader();
  //         })
  //         .catch(() => {
  //           toast.error("Error fetching LT user data.");
  //         }),
  //     ])
  //       .then(() => {
  //         localStorage.setItem("dashboardData", JSON.stringify(tempData));
  //       })
  //       .finally(() => {
  //         setInitialLoading(false);
  //       });
  //   }

  //   axios
  //     .get(
  //       `${BASE_URL}/api/v1/currentUser/${localStorage.getItem("id")}`,
  //       axiosConfig
  //     )
  //     .then((response) => {
  //       const currentUser = response.data;
  //       setUserRole(currentUser.role);
  //       setUserAssignment(
  //         currentUser.assignment && currentUser.assignment[0]
  //           ? currentUser.assignment[0]
  //           : ""
  //       );
  //     })
  //     .catch(() => {
  //       toast.error("Error fetching current user data.");
  //     });


  //   axios
  //     .get(
  //       `${BASE_URL}/api/v1/currentUser/${localStorage.getItem("id")}`,
  //       axiosConfig
  //     )
  //     .then((response) => {
  //       const currentUser = response.data;
  //       console.log(currentUser, "currentuser");

  //       setUserRole(currentUser.role);
  //       setUserAssignment(
  //         currentUser.assignment && currentUser.assignment[0]
  //           ? currentUser.assignment[0]
  //           : ""
  //       );
  //     })
  //     .catch(() => {
  //       toast.error("Error fetching current user data.");
  //     });
  // }, []);

  useEffect(() => {
    const totalAPIs = 3;
    let completedAPIs = 0;
  
    const updateLoader = () => {
      completedAPIs++;
      setLoaderPercentage((completedAPIs / totalAPIs) * 100);
      setLoaderColor("bg-yellow-500"); // Set color after each API call
    };
  
    const tempData = { userCount: 0, altCount: 0, ltCount: 0 };
  
    // Fetch data for all three user types and update the loader progress
    Promise.all([
      // axios
      //   .get(`${BASE_URL}/api/v1/hwbuser`, axiosConfig)
      //   .then((response) => {
      //     tempData.userCount = response.data.length;
      //     setUserCount(response.data.length);
      //     updateLoader();
      //   })
      //   .catch(() => {
      //     toast.error("Error fetching user data.");
      //   }),
  
      axios
        .get(`${BASE_URL}/api/v1/altuser`, axiosConfig)
        .then((response) => {
          tempData.altCount = response.data.length;
          setAltCount(response.data.length);
          updateLoader();
        })
        .catch(() => {
          toast.error("Error fetching ALT user data.");
        }),
  
      axios
        .get(`${BASE_URL}/api/v1/ltuser`, axiosConfig)
        .then((response) => {
          tempData.ltCount = response.data.length;
          setLtCount(response.data.length);
          updateLoader();
        })
        .catch(() => {
          toast.error("Error fetching LT user data.");
        }),
    ])
      .then(() => {
        // After fetching all data, store it in localStorage
        localStorage.setItem("dashboardData", JSON.stringify(tempData));
      })
      .finally(() => {
        setInitialLoading(false);
      });
  
    // Fetch the current user data
    axios
      .get(
        `${BASE_URL}/api/v1/currentUser/${localStorage.getItem("id")}`,
        axiosConfig
      )
      .then((response) => {
        const currentUser = response.data;
        setUserRole(currentUser.role);
        setUserAssignment(
          currentUser.assignment && currentUser.assignment[0]
            ? currentUser.assignment[0]
            : ""
        );
      })
      .catch(() => {
        toast.error("Error fetching current user data.");
      });
  
  }, []);
  



  
  const cardData = [


    {
      label: "Alt Holder",
      count: altCount,
      color: "bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445]",
      route: "/altlist",
      active: totals1.HC_VALID_AND_KYT_DONE,
      activeroute: "/showaltliststatedashboard",
    },
    {
      label: "LT Holder",
      count: ltCount,
      color: "bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445]",
      route: "/ltlist",
      active: totals.HC_VALID_AND_KYT_DONE,
      activeroute: "/showltliststatedashboard",
    },
  ];


  useEffect(() => {
    getData();
    getData1();
  }, []);

  const getData1 = async () => {
    try {
      setLoading1(true);
      const response = await axios.get(
        `${BASE_URL}/api/v1/altuser`,
        axiosConfig
      );
      const users = response.data;

      setUsers1(users);
      if (!Array.isArray(users)) {
        throw new Error("Fetched data is not an array");
      }

      const states = Array.from(
        new Set(users.map((user) => user.STATE || "Unknown"))
      );

      const aggregatedData = states.map((state) => {
        const today = new Date();

        const formattedToday = today.setHours(0, 0, 0, 0);
        console.log(formattedToday, "dfdjghfdg");

        const stateUsers = users.filter((user) => user.STATE === state);

        const scoutUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Scout"
        );
        const guideUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Guide"
        );
        const cubUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Cub"
        );
        const bulbulUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Bulbul"
        );
        const roversUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Rover"
        );
        const rangerUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Ranger"
        );

        const validUsers = stateUsers?.filter((user) => {
          const Dates = convertDateString(user.RENEWED_UPTO);
          const validDate = new Date(Dates);
          console.log(validDate, "validDate");
          return validDate && validDate > formattedToday;
        });

        const invalidUsers = stateUsers.filter(
          (user) => convertDateString(user.RENEWED_UPTO) < formattedToday
        );

        const HC_VALID_AND_KYT_DONE = validUsers.filter(
          (user) =>
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const valid = validUsers.length;
        const invalid = invalidUsers.length;

        const HC_valid_But_KYT_Not_Done = validUsers.filter(
          (user) =>
            !(
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            )
        ).length;

        const HC_INVALID_BUT_KYT_DONE = invalidUsers.filter(
          (user) =>
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTScout = scoutUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTGuide = guideUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTCub = cubUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTBulbul = bulbulUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTRover = roversUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTRanger = rangerUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        return {
          state,
          valid,
          validKYTScout,
          invalid,
          stateUsers,
          guideUsers,
          scoutUsers,
          cubUsers,
          bulbulUsers,
          roversUsers,
          rangerUsers,
          validKYTBulbul,
          validKYTCub,
          validKYTRanger,
          validKYTRover,
          validKYTGuide,
          HC_valid_But_KYT_Not_Done,
          HC_INVALID_BUT_KYT_DONE,
          HC_VALID_AND_KYT_DONE,
        };
      });

      // Sort the aggregated data by state in ascending order
      aggregatedData.sort((a, b) => a.state.localeCompare(b.state));

      // Set the sorted state data
      setStateData(aggregatedData);
      // Calculate totals
      const totals1 = aggregatedData.reduce(
        (acc, data) => {
          acc.valid += data.valid;
          acc.invalid += data.invalid;
          acc.validKYTScout += data.validKYTScout;
          acc.validKYTGuide += data.validKYTGuide;
          acc.validKYTCub += data.validKYTCub;
          acc.validKYTBulbul += data.validKYTBulbul;
          acc.validKYTRover += data.validKYTRover;
          acc.validKYTRanger += data.validKYTRanger;
          acc.HC_VALID_AND_KYT_DONE += data.HC_VALID_AND_KYT_DONE;
          acc.HC_valid_But_KYT_Not_Done += data.HC_valid_But_KYT_Not_Done;
          acc.HC_INVALID_BUT_KYT_DONE += data.HC_INVALID_BUT_KYT_DONE;
          return acc;
        },
        {
          valid: 0,
          invalid: 0,
          validKYTScout: 0,
          validKYTGuide: 0,
          validKYTCub: 0,
          validKYTBulbul: 0,
          validKYTRover: 0,
          validKYTRanger: 0,
          HC_VALID_AND_KYT_DONE: 0,
          HC_valid_But_KYT_Not_Done: 0,
          HC_INVALID_BUT_KYT_DONE: 0,
        }
      );

      setTotals1(totals1);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading1(false);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL}/api/v1/ltuser`,
        axiosConfig
      );
      const users = response.data;

      setUsers(users);
      if (!Array.isArray(users)) {
        throw new Error("Fetched data is not an array");
      }

      const states = Array.from(
        new Set(users.map((user) => user.STATE || "Unknown"))
      );

      const aggregatedData = states.map((state) => {
        const today = new Date();

        const formattedToday = today.setHours(0, 0, 0, 0);
        console.log(formattedToday, "dfdjghfdg");

        const stateUsers = users.filter((user) => user.STATE === state);

        const scoutUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Scout"
        );
        const guideUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Guide"
        );
        const cubUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Cub"
        );
        const bulbulUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Bulbul"
        );
        const roversUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Rover"
        );
        const rangerUsers = users.filter(
          (user) => user.STATE === state && user.SECTION === "Ranger"
        );

        const validUsers = stateUsers?.filter((user) => {
          const Dates = convertDateString(user.RENEWED_UPTO);
          const validDate = new Date(Dates);
          console.log(validDate, "validDate");
          return validDate && validDate > formattedToday;
        });

        const invalidUsers = stateUsers.filter(
          (user) => convertDateString(user.RENEWED_UPTO) < formattedToday
        );

        const HC_VALID_AND_KYT_DONE = validUsers.filter(
          (user) =>
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const valid = validUsers.length;
        const invalid = invalidUsers.length;

        const HC_valid_But_KYT_Not_Done = validUsers.filter(
          (user) =>
            !(
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            )
        ).length;

        const HC_INVALID_BUT_KYT_DONE = invalidUsers.filter(
          (user) =>
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTScout = scoutUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTGuide = guideUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTCub = cubUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTBulbul = bulbulUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTRover = roversUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        const validKYTRanger = rangerUsers.filter(
          (user) =>
            convertDateString(user.RENEWED_UPTO) > formattedToday &&
            user.dob &&
            user.dob !== "NA" &&
            user.bsgUid &&
            user.bsgUid !== "NA" &&
            user.email &&
            user.email !== "NA"
        ).length;

        return {
          state,
          valid,
          validKYTScout,
          invalid,
          stateUsers,
          guideUsers,
          scoutUsers,
          cubUsers,
          bulbulUsers,
          roversUsers,
          rangerUsers,
          validKYTBulbul,
          validKYTCub,
          validKYTRanger,
          validKYTRover,
          validKYTGuide,
          HC_valid_But_KYT_Not_Done,
          HC_INVALID_BUT_KYT_DONE,
          HC_VALID_AND_KYT_DONE,
        };
      });

      // Sort the aggregated data by state in ascending order
      aggregatedData.sort((a, b) => a.state.localeCompare(b.state));

      // Set the sorted state data
      setStateData(aggregatedData);
      // Calculate totals
      const totals = aggregatedData.reduce(
        (acc, data) => {
          acc.valid += data.valid;
          acc.invalid += data.invalid;
          acc.validKYTScout += data.validKYTScout;
          acc.validKYTGuide += data.validKYTGuide;
          acc.validKYTCub += data.validKYTCub;
          acc.validKYTBulbul += data.validKYTBulbul;
          acc.validKYTRover += data.validKYTRover;
          acc.validKYTRanger += data.validKYTRanger;
          acc.HC_VALID_AND_KYT_DONE += data.HC_VALID_AND_KYT_DONE;
          acc.HC_valid_But_KYT_Not_Done += data.HC_valid_But_KYT_Not_Done;
          acc.HC_INVALID_BUT_KYT_DONE += data.HC_INVALID_BUT_KYT_DONE;
          return acc;
        },
        {
          valid: 0,
          invalid: 0,
          validKYTScout: 0,
          validKYTGuide: 0,
          validKYTCub: 0,
          validKYTBulbul: 0,
          validKYTRover: 0,
          validKYTRanger: 0,
          HC_VALID_AND_KYT_DONE: 0,
          HC_valid_But_KYT_Not_Done: 0,
          HC_INVALID_BUT_KYT_DONE: 0,
        }
      );

      setTotals(totals);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const convertDateString = (dateString) => {
    const parts = dateString?.split("-");
    // if (parts.length !== 3) return null;
    if (!parts || parts.length !== 3) {
      return null;
    }
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day);

    return date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
      ? date
      : null;
  };

  const handleViewDetails = (state, type) => {
    const stateUsers =
      stateData.find((data) => data.state === state)?.stateUsers || [];
    const scoutUsers =
      stateData.find((data) => data.state === state)?.scoutUsers || [];
    const guideUsers =
      stateData.find((data) => data.state === state)?.guideUsers || [];
    const cubUsers =
      stateData.find((data) => data.state === state)?.cubUsers || [];
    const bulbulUsers =
      stateData.find((data) => data.state === state)?.bulbulUsers || [];
    const roversUsers =
      stateData.find((data) => data.state === state)?.roversUsers || [];
    const rangerUsers =
      stateData.find((data) => data.state === state)?.rangerUsers || [];

    let filtered = [];
    const today = new Date();

    const formattedToday = today.setHours(0, 0, 0, 0);

    if (state === "All States") {
      // Handle the case for all states
      filtered = usersData.filter((user) => {
        switch (type) {
          case "valid":
            return convertDateString(user.RENEWED_UPTO) > formattedToday;

          case "invalid":
            return convertDateString(user.RENEWED_UPTO) < formattedToday;
          case "HC_INVALID_BUT_KYT_DONE":
            return (
              convertDateString(user.RENEWED_UPTO) < formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            );

          case "HC_VALID_AND_KYT_DONE":
            return (
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            );
          case "HC_valid_But_KYT_Not_Done":
            return (
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              !(
                user.dob &&
                user.dob !== "NA" &&
                user.bsgUid &&
                user.bsgUid !== "NA" &&
                user.email &&
                user.email !== "NA"
              )
            );

          default:
            return false;
        }
      });
    } else {
      switch (type) {
        case "valid":
          filtered = stateUsers.filter(
            (user) => convertDateString(user?.RENEWED_UPTO) > formattedToday
          );
          break;

        case "HC_VALID_AND_KYT_DONE":
          filtered = stateUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;

        case "HC_valid_But_KYT_Not_Done":
          filtered = stateUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              !(
                user.dob &&
                user.dob !== "NA" &&
                user.bsgUid &&
                user.bsgUid !== "NA" &&
                user.email &&
                user.email !== "NA"
              )
          );
          break;

        case "HC_INVALID_BUT_KYT_DONE":
          filtered = stateUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) < formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTScout":
          filtered = scoutUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTGuide":
          filtered = guideUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTCub":
          filtered = cubUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTBulbul":
          filtered = bulbulUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTRover":
          filtered = roversUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTRanger":
          filtered = rangerUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "invalid":
          filtered = stateUsers.filter(
            (user) => convertDateString(user.RENEWED_UPTO) < formattedToday
          );
          break;

        default:
          break;
      }
    }
    setCurrentViewState(state);
    setFilterType(type);
    setFilteredUsers(filtered);
  };

  const handleViewDetails1 = (state, type) => {
    const stateUsers =
      stateData.find((data) => data.state === state)?.stateUsers || [];
    const scoutUsers =
      stateData.find((data) => data.state === state)?.scoutUsers || [];
    const guideUsers =
      stateData.find((data) => data.state === state)?.guideUsers || [];
    const cubUsers =
      stateData.find((data) => data.state === state)?.cubUsers || [];
    const bulbulUsers =
      stateData.find((data) => data.state === state)?.bulbulUsers || [];
    const roversUsers =
      stateData.find((data) => data.state === state)?.roversUsers || [];
    const rangerUsers =
      stateData.find((data) => data.state === state)?.rangerUsers || [];

    let filtered = [];
    const today = new Date();

    const formattedToday = today.setHours(0, 0, 0, 0);

    if (state === "All States") {
      // Handle the case for all states
      filtered = usersData1.filter((user) => {
        switch (type) {
          case "valid":
            return convertDateString(user.RENEWED_UPTO) > formattedToday;

          case "invalid":
            return convertDateString(user.RENEWED_UPTO) < formattedToday;
          case "HC_INVALID_BUT_KYT_DONE":
            return (
              convertDateString(user.RENEWED_UPTO) < formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            );

          case "HC_VALID_AND_KYT_DONE":
            return (
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
            );
          case "HC_valid_But_KYT_Not_Done":
            return (
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              !(
                user.dob &&
                user.dob !== "NA" &&
                user.bsgUid &&
                user.bsgUid !== "NA" &&
                user.email &&
                user.email !== "NA"
              )
            );

          default:
            return false;
        }
      });
    } else {
      switch (type) {
        case "valid":
          filtered = stateUsers.filter(
            (user) => convertDateString(user?.RENEWED_UPTO) > formattedToday
          );
          break;

        case "HC_VALID_AND_KYT_DONE":
          filtered = stateUsers.filter(
            (user) =>
              convertDateString(user?.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;

        case "HC_valid_But_KYT_Not_Done":
          filtered = stateUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              !(
                user.dob &&
                user.dob !== "NA" &&
                user.bsgUid &&
                user.bsgUid !== "NA" &&
                user.email &&
                user.email !== "NA"
              )
          );
          break;

        case "HC_INVALID_BUT_KYT_DONE":
          filtered = stateUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) < formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTScout":
          filtered = scoutUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTGuide":
          filtered = guideUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTCub":
          filtered = cubUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTBulbul":
          filtered = bulbulUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTRover":
          filtered = roversUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "validKYTRanger":
          filtered = rangerUsers.filter(
            (user) =>
              convertDateString(user.RENEWED_UPTO) > formattedToday &&
              user.dob &&
              user.dob !== "NA" &&
              user.bsgUid &&
              user.bsgUid !== "NA" &&
              user.email &&
              user.email !== "NA"
          );
          break;
        case "invalid":
          filtered = stateUsers.filter(
            (user) => convertDateString(user.RENEWED_UPTO) < formattedToday
          );
          break;

        default:
          break;
      }
    }
    setCurrentViewState1(state);
    setFilterType1(type);
    setFilteredUsers1(filtered);
  };

  const handleBackToDashboard = () => {
    setCurrentViewState(null);
    setFilterType(null);
    setFilteredUsers([]);
  };

  const handleBackToDashboard1 = () => {
    setCurrentViewState1(null);
    setFilterType1(null);
    setFilteredUsers1([]);
  };
  const handleCloseModal = () => {
    setSelectedUser(null); // Close the modal
  };
  const handleCloseModal1 = () => {
    setSelectedUser1(null); // Close the modal
  };

  const handleView = (userId) => {
    console.log(userId, "userId");
    const user = filteredUsers.find((user) => user._id === userId);
    console.log(user, "user");
    setSelectedUser(user); // Set the selected user
  };

  const handleView1 = (userId) => {
    console.log(userId, "userId");
    const user = filteredUsers1.find((user) => user._id === userId);
    console.log(user, "user");
    setSelectedUser1(user); // Set the selected user
  };

  const renderStateTable = () => (
    <>
      <div className="max-h-[400px] overflow-y-auto">
        <table className="min-w-full bg-white border rounded-lg shadow-lg">
          <thead className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
            <tr>
              {[
                // "State",
                "Valid",
                "Invalid",
                "Valid-KYT",
                "Invalid+KYT",
                "Valid+KYT",
                "Scout",
                "Guide",
                "Cub",
                "Bulbul",
                "Rover",
                "Ranger",
              ].map((header) => (
                <th
                  key={header}
                  className="px-2 py-1 text-left text-xs font-semibold uppercase sticky top-0 bg-gradient-to-r from-indigo-600 to-purple-600"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(stateData) && stateData.length > 0 ? (
              stateData.map((data, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-100 transition-all duration-200 border-b last:border-none"
                ></tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  No data available
                </td>
              </tr>
            )}

            <tr className="font-bold bg-gray-200 sticky bottom-0">
              {/* <td className="px-4 py-1 text-gray-700">Total</td> */}
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() => handleViewDetails("All States", "valid")}
              >
                {totals.valid}
              </td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() => handleViewDetails("All States", "invalid")}
              >
                {totals.invalid}
              </td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() =>
                  handleViewDetails("All States", "HC_valid_But_KYT_Not_Done")
                }
              >
                {totals.HC_valid_But_KYT_Not_Done}
              </td>
              <td
                className="px- 4 py-1 text-gray-700 cursor-pointer"
                onClick={() =>
                  handleViewDetails("All States", "HC_INVALID_BUT_KYT_DONE")
                }
              >
                {totals.HC_INVALID_BUT_KYT_DONE}
              </td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() =>
                  handleViewDetails("All States", "HC_VALID_AND_KYT_DONE")
                }
              >
                {totals.HC_VALID_AND_KYT_DONE}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals.validKYTScout}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals.validKYTGuide}
              </td>
              <td className="px-4 py-1 text-gray-700">{totals.validKYTCub}</td>
              <td className="px-4 py-1 text-gray-700">
                {totals.validKYTBulbul}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals.validKYTRover}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals.validKYTRanger}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );

  const renderFilteredUsersTable = () => (
    <div className="">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center mb-3">
        <div>
          <h2 className="text-md font-bold text-gray-800  uppercase tracking-wide">
            {`${currentViewState}`}
          </h2>
          <h2 className="text-md font-bold text-gray-800">
            {`(${filterType.toUpperCase()})`}
          </h2>
        </div>
        <div className="flex justify-end">
          <button
            className="px-6 py-2 bg-[#1D56A5] text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-400 transition-all duration-300"
            onClick={handleBackToDashboard}
          >
            Back to Dashboard
          </button>
        </div>
      </div>

      <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gradient-to-r from-green-600 to-teal-600 text-white">
          <tr>
            {[
              "No",
              "Name",
              "State",
              "Section",
              "Renewed Upto",
              "Email",
              "Action",
            ].map((header) => (
              <th
                key={header}
                className="px-4 py-1 text-left text-sm font-semibold uppercase"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
            filteredUsers.map((user, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 transition-all duration-200 border-b last:border-none"
              >
                <td className="px-1 py-1 text-gray-700  text-xs font-medium">
                  {index + 1}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.name || "N/A"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.STATE || "Unknown"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.SECTION || "N/A"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.RENEWED_UPTO || "22-09-2029"}
                </td>

                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.email}
                </td>
                <td
                  className="px-1 py-1 text-red-500 text-xs  font-bold cursor-pointer"
                  onClick={() => handleView(user._id)}
                >
                  View All
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No users found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  const renderStateTable1 = () => (
    <>
      <div className="max-h-[400px] overflow-y-auto">
        <table className="min-w-full bg-white border rounded-lg shadow-lg">
          <thead className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white">
            <tr>
              {[
                "State",
                "Valid",
                "Invalid",
                "Valid-KYT",
                "Invalid+KYT",
                "Valid+KYT",
                "Scout",
                "Guide",
                "Cub",
                "Bulbul",
                "Rover",
                "Ranger",
              ].map((header) => (
                <th
                  key={header}
                  className="px-2 py-1 text-left text-xs font-semibold uppercase sticky top-0 bg-gradient-to-r from-indigo-600 to-purple-600"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(stateData) && stateData.length > 0 ? (
              stateData.map((data, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-100 transition-all duration-200 border-b last:border-none"
                ></tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  No data available
                </td>
              </tr>
            )}

            <tr className="font-bold bg-gray-200 sticky bottom-0">
              <td className="px-4 py-1 text-gray-700">Total</td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() => handleViewDetails1("All States", "valid")}
              >
                {totals1.valid}
              </td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() => handleViewDetails1("All States", "invalid")}
              >
                {totals1.invalid}
              </td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() =>
                  handleViewDetails1("All States", "HC_valid_But_KYT_Not_Done")
                }
              >
                {totals1.HC_valid_But_KYT_Not_Done}
              </td>
              <td
                className="px- 4 py-1 text-gray-700 cursor-pointer"
                onClick={() =>
                  handleViewDetails1("All States", "HC_INVALID_BUT_KYT_DONE")
                }
              >
                {totals1.HC_INVALID_BUT_KYT_DONE}
              </td>
              <td
                className="px-4 py-1 text-gray-700 cursor-pointer"
                onClick={() =>
                  handleViewDetails1("All States", "HC_VALID_AND_KYT_DONE")
                }
              >
                {totals1.HC_VALID_AND_KYT_DONE}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals1.validKYTScout}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals1.validKYTGuide}
              </td>
              <td className="px-4 py-1 text-gray-700">{totals1.validKYTCub}</td>
              <td className="px-4 py-1 text-gray-700">
                {totals1.validKYTBulbul}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals1.validKYTRover}
              </td>
              <td className="px-4 py-1 text-gray-700">
                {totals1.validKYTRanger}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );

  const renderFilteredUsersTable1 = () => (
    <div className="">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center mb-3">
        <div>
          <h2 className="text-md font-bold text-gray-800  uppercase tracking-wide">
            {`${currentViewState1}`}
          </h2>
          <h2 className="text-md font-bold text-gray-800">
            {`(${filterType1.toUpperCase()})`}
          </h2>
        </div>
        <div className="flex justify-end">
          <button
            className="px-6 py-2 bg-[#1D56A5] text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-400 transition-all duration-300"
            onClick={handleBackToDashboard1}
          >
            Back to Dashboard
          </button>
        </div>
      </div>

      <table className="min-w-full bg-white border rounded-lg shadow-lg overflow-hidden">
        <thead className="bg-gradient-to-r from-green-600 to-teal-600 text-white">
          <tr>
            {[
              "No",
              "Name",
              "State",
              "Section",
              "Renewed Upto",
              "Email",
              "Action",
            ].map((header) => (
              <th
                key={header}
                className="px-4 py-1 text-left text-sm font-semibold uppercase"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(filteredUsers1) && filteredUsers1.length > 0 ? (
            filteredUsers1.map((user, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 transition-all duration-200 border-b last:border-none"
              >
                <td className="px-1 py-1 text-gray-700  text-xs font-medium">
                  {index + 1}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.name || "N/A"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.STATE || "Unknown"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.SECTION || "N/A"}
                </td>
                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.RENEWED_UPTO || "22-09-2029"}
                </td>

                <td className="px-1 py-1 text-gray-700 text-xs font-medium">
                  {user.email}
                </td>
                <td
                  className="px-1 py-1 text-red-500 text-xs  font-bold cursor-pointer"
                  onClick={() => handleView1(user._id)}
                >
                  View All
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No users found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  const today = new Date();

  const formattedToday = today.setHours(0, 0, 0, 0);

  const HC_INVALID_BUT_KYT_DONEEmails = usersData
    .filter(
      (user) =>
        convertDateString(user.RENEWED_UPTO) < formattedToday &&
        user.dob &&
        user.dob !== "NA" &&
        user.bsgUid &&
        user.bsgUid !== "NA" &&
        user.email &&
        user.email !== "NA"
    )
    .map((user) => user.email);

  return (
    <>
      <div className=" p-4">
        <ToastContainer />
        {initialLoading ? (
          <div className="flex items-center justify-center h-screen bg-gray-100 ">
            <div className="relative w-56 h-56 -mt-32">
              {/* Circle background */}
              <div className="absolute inset-0 rounded-full border-8 border-gray-300"></div>

              {/* Loader circle */}
              <div
                className={`absolute inset-0 rounded-full border-8 ${loaderColor}`}
                style={{
                  clipPath: "inset(0 50% 0 0)",
                  transform: `rotate(${(loaderPercentage / 100) * 360}deg)`,
                  transformOrigin: "50% 50%",
                }}
              ></div>

              {/* Center text */}
              <div className="absolute inset-0 flex items-center justify-center text-center">
                <span className="text-lg font-semibold text-gray-800 animate-pulse">
                  Loading... {Math.round(loaderPercentage)}%
                </span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {cardData.map((item, index) => (
                <div
                  key={index}
                  className={`bg-gradient-to-r ${item.color} text-white rounded-lg shadow-lg p-5 h-56`}
                >
                  <div className="text-center font-bold text-lg mb-2 uppercase">
                    {item.label}
                  </div>
                  <div
                    className="text-center text-4xl font-extrabold mb-2 cursor-pointer"
                    onClick={() => navigate(item.activeroute)}
                    data-tooltip-id={`count-tooltip-${index}`}
                  >
                    <CountUp
                      start={0}
                      end={item.active}
                      duration={3}
                      separator=","
                    />
                  </div>
                  <Tooltip
                    id={`count-tooltip-${index}`}
                    content="Click to view details"
                  />

                  <h1
                    className="border border-[#4dc9ff] bg-[#192142] text-white font-semibold flex text-center justify-center px-3 rounded-md shadow-md cursor-pointer"
                    onClick={() => navigate(item.route)}
                    data-tooltip-id={`total-tooltip-${index}`}
                  >
                    Total List: {item.count}
                  </h1>
                  <Tooltip
                    id={`total-tooltip-${index}`}
                    content="Click to view the total list"
                  />

                  <div className="flex justify-around mt-4">
                    <button
                      className="border border-[#4dc9ff] bg-[#192142] text-white font-semibold px-3 rounded-md shadow-md"
                      onClick={() =>
                        navigate(`/show${item.route.slice(1)}dashboard`)
                      }
                      data-tooltip-id={`dashboard-tooltip-${index}`}
                    >
                      Dashboard
                    </button>
                    <Tooltip
                      id={`dashboard-tooltip-${index}`}
                      content="Go to Dashboard"
                    />
                  </div>

                  <button
                    className="my-2 border border-[#4dc9ff] bg-[#192142] text-white font-semibold flex text-center justify-center px-3 rounded-md shadow-md cursor-pointer"
                    onClick={() =>
                      navigate(`/show${item.route.slice(1)}statedashboard`)
                    }
                    data-tooltip-id={`state-dashboard-tooltip-${index}`}
                  >
                    State Dashboard
                  </button>
                  <Tooltip
                    id={`state-dashboard-tooltip-${index}`}
                    content="Go to State Dashboard"
                  />
                </div>
              ))}

              {role === "superadmin" && (
                <div className="flex-shrink-0 bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white rounded-lg shadow-lg p-4 w-full h-56 bg-lime-400">
                  <div className="text-center font-bold text-lg mb-2 uppercase">
                    Create Admin
                  </div>
                  <div className="text-center font-semibold">
                    {[
                      "NTC Uploader",
                      "NHQ Checker",
                      "CNC-Approver",
                      "Super Admin",
                    ].map((adminRole, index) => (
                      <span
                        key={index}
                        className="text-white cursor-pointer rounded-md px-3 text-md uppercase border border-[#4dc9ff] bg-[#192142] shadow-md hover:shadow-lg transition-all block mt-2"
                        style={{ fontFamily: "Roboto Mono, monospace" }}
                        data-tooltip-id={`admin-role-tooltip-${index}`}
                        onClick={() => {
                          navigate("/create-admin");
                        }}
                      >
                        {adminRole}
                      </span>
                    ))}
                    <Tooltip
                      id="admin-role-tooltip-0"
                      content="NTC Uploader Role"
                    />
                    <Tooltip
                      id="admin-role-tooltip-1"
                      content="NHQ Checker Role"
                    />
                    <Tooltip
                      id="admin-role-tooltip-2"
                      content="CNC-Approver Role"
                    />
                    <Tooltip
                      id="admin-role-tooltip-3"
                      content="Super Admin Role"
                    />
                  </div>
                </div>
              )}

              {(role === "superadmin" ||
                assignment === "nhq_checker" ||
                assignment === "cnc_approval") && (
                <>
                  <div className="flex-shrink-0 bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white rounded-lg shadow-lg p-4 w-full h-56 bg-red-800">
                    <div className="text-center font-bold text-xl mb-2 uppercase">
                      ALT VERIFIED APPLICATION
                    </div>
                    <div
                      className="text-center font-semibold mt-8"
                      onClick={() => navigate("/alt-verify")}
                    >
                      <span
                        className="text-red-500 bg-white cursor-pointer border border-[#4dc9ff]  rounded-md py-1 px-2 text-lg"
                        style={{ fontFamily: "Roboto Mono, monospace" }}
                        data-tooltip-id="alt-verify-tooltip"
                      >
                        ✔ Verified
                      </span>
                      <Tooltip
                        id="alt-verify-tooltip"
                        content="View ALT Verified Applications"
                      />
                    </div>
                  </div>

                  <div className="flex-shrink-0 bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white rounded-lg shadow-lg p-4 w-full h-56 bg-green-800">
                    <div className="text-center font-bold text-xl mb-2 uppercase">
                      LT VERIFIED APPLICATION
                    </div>
                    <div
                      className="text-center font-semibold mt-8"
                      onClick={() => navigate("/lt-verify")}
                    >
                      <span
                        className="text-red-500 bg-white cursor-pointer border border-[#4dc9ff]  rounded-md py-1 px-2 text-lg"
                        style={{ fontFamily: "Roboto Mono, monospace" }}
                        data-tooltip-id="alt-verify-tooltip"
                      >
                        ✔ Verified
                      </span>
                      <Tooltip
                        id="lt-verify-tooltip"
                        content="View LT Verified Applications"
                      />
                    </div>
                  </div>
            
               


                </>
              )}

               
<div className="flex-shrink-0 bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] text-white rounded-lg shadow-lg p-4 w-full h-56 bg-green-800">
                    <div className="text-center font-bold text-xl mb-2 uppercase">
                      KNOW YOUR TRAINERS
                    </div>
                    <div
                      className="text-center font-semibold mt-8"
                      onClick={() => navigate("/kyt")}
                    >
                      <span
                        className="text-red-500 bg-white cursor-pointer border border-[#4dc9ff]  rounded-md py-1 px-2 text-lg"
                        style={{ fontFamily: "Roboto Mono, monospace" }}
                        data-tooltip-id="alt-verify-tooltip"
                      >
                        KYT DATA
                      </span>
                      <Tooltip id="lt-verify-tooltip" content="View KYT DATA" />
                    </div>
                  </div>
            </div>
          </>
        )}
      </div>

      <div className="px-4 sm:px-8 py-5 bg-gradient-to-r from-blue-50 to-purple-50 ">
        <div className="text-center ">
          <h1 className="text-3xl font-extrabold text-indigo-700 uppercase">
            LT Statewise Data
          </h1>
          <p className="text-gray-600 text-lg ">
            An overview of statewise user statistics
          </p>
        </div>
        {loading ? (
          <div className="flex justify-center items-center min-h-[200px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-600 border-opacity-70"></div>
          </div>
        ) : (
          <div className="bg-white rounded-xl p-5 shadow-2xl">
            {currentViewState ? renderFilteredUsersTable() : renderStateTable()}
          </div>
        )}
      </div>

      {selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-xl">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6 border-b pb-3">
              User Details
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm  text-black font-bold">Name</p>
                <p className="text-gray-800">{selectedUser.name || "N/A"}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Email</p>
                <p className="text-gray-800">{selectedUser.email}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">DOB</p>
                <p className="text-gray-800">{selectedUser.dob}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">BSG UID</p>
                <p className="text-gray-800">{selectedUser.bsgUid}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">
                  Honourable Charge No
                </p>
                <p className="text-gray-800">
                  {selectedUser.HONOURABLE_CHARGE_NO ||
                    selectedUser.honourableNumber}
                </p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">State</p>
                <p className="text-gray-800">{selectedUser.STATE}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Status</p>
                <p className="text-gray-800">{selectedUser.STATUS}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Region</p>
                <p className="text-gray-800">{selectedUser.REGION}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Issued Date</p>
                <p className="text-gray-800">{selectedUser.DATE}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Admin Status</p>
                <p className="text-gray-800">{selectedUser.status}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Renewed Upto</p>
                <p className="text-gray-800">{selectedUser.RENEWED_UPTO}</p>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={handleCloseModal}
                className="bg-[#1D56A5] text-white py-2 px-4 rounded-lg shadow"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="px-4 sm:px-8 py-5 bg-gradient-to-r from-blue-50 to-purple-50 min-h-screen">
        <div className="text-center ">
          <h1 className="text-3xl font-extrabold text-indigo-700 uppercase">
            ALT Statewise Data
          </h1>
          <p className="text-gray-600 text-lg ">
            An overview of statewise user statistics
          </p>
        </div>
        {loading1 ? (
          <div className="flex justify-center items-center min-h-[200px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-600 border-opacity-70"></div>
          </div>
        ) : (
          <div className="bg-white rounded-xl p-5 shadow-2xl">
            {currentViewState1
              ? renderFilteredUsersTable1()
              : renderStateTable1()}
          </div>
        )}
      </div>
      {selectedUser1 && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-xl">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6 border-b pb-3">
              User Details
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm  text-black font-bold">Name</p>
                <p className="text-gray-800">{selectedUser1.name || "N/A"}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Email</p>
                <p className="text-gray-800">{selectedUser1.email}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">DOB</p>
                <p className="text-gray-800">{selectedUser1.dob}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">BSG UID</p>
                <p className="text-gray-800">{selectedUser1.bsgUid}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">
                  Honourable Charge No
                </p>
                <p className="text-gray-800">
                  {selectedUser1.HONOURABLE_CHARGE_NO ||
                    selectedUser1.honourableNumber}
                </p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">State</p>
                <p className="text-gray-800">{selectedUser1.STATE}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Status</p>
                <p className="text-gray-800">{selectedUser1.STATUS}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Region</p>
                <p className="text-gray-800">{selectedUser1.REGION}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Issued Date</p>
                <p className="text-gray-800">{selectedUser1.DATE}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Admin Status</p>
                <p className="text-gray-800">{selectedUser1.status}</p>
              </div>
              <div>
                <p className="text-sm  text-black font-bold">Renewed Upto</p>
                <p className="text-gray-800">{selectedUser1.RENEWED_UPTO}</p>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={handleCloseModal1}
                className="bg-[#1D56A5] text-white py-2 px-4 rounded-lg shadow"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
