import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Assuming you have jwt-decode library installed

const ProtectedRoute = (props) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    console.log(token, "Token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
        if (decodedToken.exp > currentTime) {
          // Token is valid
          setShow(true);
        } else {
          localStorage.clear();

          // Token has expired, redirect to login
          navigate("/");
        }
      } catch (error) {
        localStorage.clear();

        // Error decoding token, redirect to login
        navigate("/");
      }
    } else {
      // Token not found, redirect to login
      localStorage.clear();

      navigate("/");
    }
  }, [navigate]);

  return show && <props.component />;
};

export default ProtectedRoute;
