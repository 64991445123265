import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./Component/Sidebar";
import Login from "./Component/AdminlLogin";
import Superadmin from "./Component/SuperAdmin";
import ErrorPage from "./PageNotFound";

const App = () => {
  const location = useLocation();

  // Check if the current location matches '/setpassword' or '/newuserlogin'
  const isLoginRoute = location.pathname === "/";
  const isSuperAmdinLoginRoute = location.pathname === "/superadmin";

  const token=localStorage.getItem("adminToken")
  if (!token && location.pathname !== "/") {
    // Redirect to error page if token is not available (excluding login route).
    return <ErrorPage />;
  }

  return (
    <>
      {/* Display Sidebar only if it's not a login, setpassword, or newuserlogin route */}
      {!isLoginRoute && !isSuperAmdinLoginRoute && <Home />}

      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/home" exact element={<Home />} />
        <Route path="/superadmin" exact element={<Superadmin />} />
     
      </Routes>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
