
import React, { useState, useEffect } from "react";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constant/constant";
const AddBanner = () => {
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState([]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const userId=localStorage.getItem("id")
      console.log(userId,"userId");

      const response = await axios.post(
        `${BASE_URL}/api/v1/addcarousel/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data, "datasss");
      toast.success("Carousel added successfully!")
  
      // Refresh the banner list after adding a new one
      getState();
    } catch (error) {
      console.error("Error adding banner:", error);
    }
  };

  const getState = async () => {
    try {
      const userId=localStorage.getItem("id")
      console.log(userId,"userId")
      const response = await axios.get(
        `${BASE_URL}/api/v1/getcarousel/${userId}`
      );
      console.log(response, "response");
      console.log(response.data);
      setFile1(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getState();
  }, []);


  const handleDelete = async (id) => {
    try {
      console.log(id,"id");
      const response = await fetch(
        `${BASE_URL}/api/v1/deletecarousel/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        console.log("Category deleted successfully");
getState();
        
      } else {
        console.error("Failed to delete category:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <div className="bg-slate-100 h-screen">
    <div className="flex justify-center ">
      <div className="bg-white p-8 rounded-lg shadow-lg  border border-[#4dc9ff]  ">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center uppercase">
          Add Carousel
        </h2>
        <input
          type="file"
          className="block w-full mb-4 border border-[#4dc9ff]   rounded-lg py-2 px-4"
          onChange={handleFileChange}
        />
        <button
          className="w-full border border-[#4dc9ff]  bg-gradient-to-r from-[#0c1445] via-[#1b3f7c] to-[#0c1445] transform active:scale-95 ease-in-out  disabled:opacity-50 transition duration-200 text-white px-6 py-3 rounded-lg shadow-lg"
          onClick={handleUpload}
        >
          Add Carousel
        </button>
        </div>    </div>





<div className="font-bold text-2xl text-black mb-6 uppercase">
        All Carousel
      </div>
<div className="mt-6 grid grid-cols-3 gap-6">
  {file1.map((item, index) => {
    console.log(item, "item"); // Log the item to the console
    return (
      <div
        key={item._id}
        className="relative group bg-gray-100 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
      >
        <img
          src={`${BASE_URL}/uploads/${item.filename}`}
          alt="photo"
          className="w-full h-40 object-cover"
        />
        <button
          className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-md text-red-500 hover:text-red-700 hover:bg-red-100 transition duration-300 transform group-hover:scale-110"
          onClick={() => handleDelete(item._id)}
        >
          <MdDelete
            style={{
              fontSize: 24,
            }}
          />
        </button>
      </div>
    );
  })}
</div>

    </div>
  );
};

export default AddBanner;